/* Backdrop */

.Backdrop {
    height: 9000px;
    width: 9000px;
    background-color: rgba($cc-black, .4);
    display: none;
    opacity: 0;
    pointer-events: none;
}
