/* Pixels */

.Pixels {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: flex;
    pointer-events: none;
    overflow: hidden;

    &-item {
        flex-shrink: 0;
    }
    svg {
        display: block;
        position: relative;
        left: -1px;
        height: 10rem;
        margin-left: -1px;
        @include mq($viewport--lg) {
            height: 20rem;
        }
    }
    path,
    polygon,
    rect {
        fill: $white;
    }

    &--bg-white {
        path,
        polygon,
        rect {
            fill: $white;
        }
    }
    &--bg-grey-100 {
        path,
        polygon,
        rect {
            fill: $cc-grey--100;
        }
    }
    &--bg-grey-700 {
        path,
        polygon,
        rect {
            fill: $cc-grey--700;
        }
    }
    &--bg-black {
        path,
        polygon,
        rect {
            fill: $cc-black;
        }
    }
}
