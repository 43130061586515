.Nav--pagination {

    .Nav-list {
        justify-content: center;
        align-items: center;
        margin: -.75rem;
    }
    .Nav-item {
        margin: .75rem;
    }
    .Nav-link {
        min-height: 3.5rem;
        min-width: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $black;
        @include mq($from: $viewport--md) {
            min-height: 5rem;
            min-width: 5rem;
        }
        &.is-active,
        &:hover,
        &:focus {
            color: $white;
            background-color: $black;
        }
        &.is-active {
            &:hover,
            &:focus {
                background-color: rgba($black, .8);
            }
        }

        &--prev,
        &--next {
            &:hover,
            &:focus {
                path {
                    fill: $white;
                }
            }
        }

        &--prev {

        }
        &--next {
            svg {
                transform: rotate(180deg);
            }
        }

        path {
            transition: .2s fill linear;
        }
    }
}
