/* Fields: input */
.#{$form-namespace}Form-field--input,
.#{$form-namespace}Form-field--textarea {
    @extend %Form-field--reset;
    @extend %Form-field--shadow;
}

.#{$form-namespace}Form-field--input,
.#{$form-namespace}Form-field--textarea {
    padding: rem($form-element-padding--t, $form-element-padding--r, $form-element-padding--b, $form-element-padding--l);
}
