/* New Hero */

.NewHero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: $cc-grey--700;
    min-height: 48rem;
    @include mq($viewport--sm) {
        min-height: 54rem;
    }
    @include mq(920) {
        min-height: 55vh;
    }
    @include mq($viewport--xl) {
        min-height: 75vh;
    }
    @include mq(1400) {
        min-height: 100vh;
    }
    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: radial-gradient(circle, $cc-grey--700 0%, $cc-black 100%);
        mix-blend-mode: multiply;
        opacity: .35;
    }
}

.NewHero-wrapper {
    position: relative;
    z-index: 3;
    height: 100%;
}

.NewHero-body {
    height: 100%;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    align-items: flex-start;
    padding: 12.5rem 0 7.5rem;
    color: $cc-grey--100;
    opacity: 0;
    animation: fadeIn 1.75s ease-in .25s 1 forwards;
    @include mq($viewport--md) {
        padding: 15rem 0 10rem;
    }
    @include mq($from: 920) {
        padding: 25rem 0 15rem;
    }
    @include mq($viewport--lg) {
        gap: 5rem;
    }
    @include mq($viewport--xl) {
        max-width: 100rem;
    }
    @media screen and (min-width: add-unit((strip-unit(1400) / 16), em)) and (min-height: add-unit((strip-unit(1200) / 16), em)) {
        max-width: 140rem;
        padding-bottom: 25rem;
    }
}

.NewHero-media {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.NewHero-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0;
    animation: fadeIn 2s ease-in 1 forwards;
    @media (hover: none) and (pointer: coarse) {
        display: none;
    }
}

.NewHero-title {
    position: relative;
    font-weight: 500;
    color: $cc-grey--100;
    margin-bottom: 0;
    @include mq($viewport--xl) {
        font-size: 6.8rem;
    }
    @media screen and (min-width: add-unit((strip-unit(1400) / 16), em)) and (min-height: add-unit((strip-unit(1200) / 16), em)) {
        font-size: 8rem;
    }
    strong {
        font-weight: normal;
        color: $cc-grey--700;
        span {
            display: inline-block;
            font-weight: 500;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0.5rem;
                left: -0.75rem;
                height: calc(100% - .5rem);
                width: calc(100% + 1.5rem);
                background-color: $cc-green;
            }
        }
    }
}

.NewHero-text {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.5;
    @include mq($viewport--lg) {
        width: 80%;
    }
    @include mq($viewport--xl) {
        font-size: 2.4rem;
    }
    > * {
        margin-bottom: 0;
    }
}

.NewHero-text + .NewHero-button {
    //margin-top: -1.5rem;
}

.NewHero-pixels {
    display: none;
    position: absolute;
    z-index: 3;
    top: 45rem;
    right: 4rem;
    pointer-events: none;
    width: 20rem;
    height: 11rem;
    transform-origin: top right;
    transform: rotate(90deg);
    @include mq($viewport--lg) {
        display: block;
    }
    @media screen and (min-width: add-unit((strip-unit(1400) / 16), em)) and (min-height: add-unit((strip-unit(1200) / 16), em)) {
        top: 30rem;
        width: 30rem;
        height: 16.5rem
    }
    path,
    polygon,
    rect {
        fill: $cc-green;
    }
}

.NewHero-mouse {
    display: none;
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include mq($viewport--xl) {
        display: flex;
    }
    .Mouse {
        background-color: transparent;
        @include mq($viewport--xl) {
            height: 8rem;
            width: 8rem;
        }
    }
}

.NewHero .Container {
    height: 100%;
}
