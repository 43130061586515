/*
    Quote
 */

.Quote {
    position: relative;
    z-index: 1;
    padding-top: 6rem;

    > *:last-child {
        margin-bottom: 0;
    }
}

.Quote-text {
    margin-bottom: 3.5rem;
    @include mq($viewport--lg) {
        width: 75%;
    }
    @include mq($viewport--lg) {
        width: 100%;
        max-width: 58rem;
    }
    > *:last-child {
        margin-bottom: 0;
    }
    p {
        font-family: $font-saira;
        @include font-size($heading-size-3, $heading-size-3);
        font-weight: 300;
        @include mq($viewport--lg) {
            font-size: 3.5rem;
        }
    }
    a {
        color: $white;
        &:hover, &:focus {
            color: rgba($white, .75);
        }
    }
}

.Quote-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 6rem;
    rect {
        fill: $cc-green;
    }
}

.Quote--slim {
    .Quote-text {
        p {
            font-size: 2.6rem;
        }
    }
}
