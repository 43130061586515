/* Mouse */

.Mouse {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $cc-grey--700;
    height: 6.5rem;
    width: 6.5rem;
    pointer-events: none;

    svg {
        display: block;
        height: 3.2rem;
        width: 1.8rem;
    }
    path,
    circle {
        fill: $white;
    }
    circle {
        animation-name: scroll;
        animation-duration: 3s;
        animation-iteration-count: infinite
    }
}
