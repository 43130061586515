/*
    Big Quote
 */

.BigQuote {
    position: relative;
    background-color: $cc-grey--700;
    color: $white;
    overflow: hidden;
}

.BigQuote-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    picture {
        height: 100%;
        &:before {
            content: none;
        }
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba($cc-black, .3) 0%, rgba($cc-black, .2) 100%);
        pointer-events: none;
    }
}

.BigQuote-text {
    position: relative;
    z-index: 1;
    padding: 12rem 0 9rem;

    @include mq($from: $viewport--md) {
        padding: 12rem 0;
    }
    @include mq($from: $viewport--lg) {
        padding: 18rem 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    &Wrap {
        position: relative;
        max-width: 78rem;
        > *:last-child {
            margin-bottom: 0;
        }
    }

    blockquote {
        margin-bottom: 3rem;
        > *:last-child {
            margin-bottom: 0;
        }
        p {
            font-family: $font-saira;
            @include font-size($heading-size-2, $heading-spacing-2);
            font-weight: 300;
            @include mq($viewport--lg) {
                font-size: 5.5rem;
            }
        }
        a {
            color: $white;
            &:hover, &:focus {
                color: rgba($white, .75);
            }
        }
    }
}

.BigQuote-icon {
    position: absolute;
    bottom: calc(100% + 4rem);
    left: 0;
    display: block;
    width: 6rem;
    rect {
        fill: $cc-green;
    }
}

.BigQuote + .Pixels {

}
