/* Patio */

.Patio {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

    @include mq($from: $viewport--md) {
        grid-gap: 2rem;
    }

    &-item {
        position: relative;

        @include mq($from: $viewport--sm) {

        }
        @include mq($from: $viewport--md) {

        }

        &.Patio-item--half {

        }
        &.Patio-item--full {
            grid-column: 2 span;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
