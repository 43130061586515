/* Social Media */
.Nav--socialMedia {


    // List
    .Nav-list {
        gap: 1rem;
    }

    // Item
    .Nav-item {

    }

    // Link
    .Nav-link {
        background-color: $cc-grey--700;
        color: $cc-green;
        height: 4rem;
        width: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            display: block;
            height: 1.6rem;
            width: 1.6rem;
        }
        path {
            fill: $cc-green;
        }

        &:focus,
        &:hover {
            background-color: darken($cc-grey--700, 4%);
        }

        // - active
        // Active state
        &.is-active {
            &:before {
                content: "";
                position: absolute;
                bottom: -.5rem;
                left: 0;
                height: .3rem;
                width: 1.5rem;
                background-color: $cc-green;
            }
        }
    }
}
