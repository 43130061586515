/* Components: Button */

// Namespace
$button-namespace:                    $namespace !default;

// Padding
$button-padding--t:                   1.5rem  !default;
$button-padding--r:                   3rem !default;
$button-padding--b:                   1.5rem !default;
$button-padding--l:                   3rem !default;

// Typography
$button-font-stack:                   $font-saira !default;
$button-font-weight:                  500 !default;
$button-line-height:                  1.2 !default;
$button-font-size:                    1.4rem !default;
$button-text-align:                   center !default;

// Borders
$button-border-width:                 1 !default;
$button-border-radius:                4 !default;

// States
$button-focus-width:                  0.2rem !default;
$button-focus-color:                  rgba($blue, 0.25) !default;
$button-focus-shadow:                 0 0 0 $button-focus-width $button-focus-color !default;

// Disabled
$button-disabled-opacity:             60% !default;
