/* Big Hero */

.BigHero {
    position: relative;
    padding: 3rem 0 5rem;
    background-color: $cc-grey--100;

    @include mq($viewport--md) {
        padding-bottom: 6rem;
    }
    @include mq($viewport--lg) {
        padding-bottom: 9rem;
    }
    @include mq(1400) {
        min-height: calc(100Vh - #{u($structure-header-height)});
    }
    @media screen and (min-width: add-unit((strip-unit(1400) / 16), em)) and (min-height: add-unit((strip-unit(1200) / 16), em)) {
        min-height: 0;
        max-height: 86rem;
    }

    // Wrapper
    &-wrapper {
        position: relative;
        height: 100%;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-gap: 3rem;

        @include mq($from: $viewport--md) {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-flow: dense;
            grid-gap: 6rem;
            align-items: center;
        }
        @include mq($viewport--xl) {
            grid-gap: 12rem;
        }
    }

    // Body
    &-body {
        flex: 1 1 50%;
        @include mq($viewport--md) {
            padding: 2.5rem 2.5rem 2.5rem 0;
            grid-column: 2;
        }
        @include mq($viewport--lg) {
            padding-right: 7.5rem;
        }
    }

    // - title
    &-title {
        position: relative;
        z-index: 2;
        font-weight: 500;
        strong {
            font-weight: normal;
            span {
                display: inline-block;
                font-weight: 500;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0.5rem;
                    left: -0.75rem;
                    height: calc(100% - .5rem);
                    width: calc(100% + 1.5rem);
                    background-color: $cc-green;
                }
            }
        }
    }

    // - text
    &-text {
        margin-bottom: 2.5rem;
        @include mq($viewport--lg) {
            margin-bottom: 5rem;
            width: 75%;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    // Media
    &-media {
        position: relative;
        @include mq($viewport--md) {
            grid-column: 1;
        }
        @include mq(1400) {
            height: 100%;
        }
        picture {
            @include mq($viewport--md) {
                height: 100%;
            }
            @include mq($viewport--lg) {
                height: auto;
            }
            @include mq(1400) {
                height: 100%;
            }
            &:before {
                @include mq($until: $viewport--md - 1) {
                    padding-bottom: 56.25%;
                }
            }
        }
        img {
            object-fit: cover;
        }
    }

    // - pixels
    &-pixels {
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        display: block;
        width: 10rem;
        height: 5.5rem;
        @include mq($viewport--lg) {
            width: 20rem;
            height: 11rem;
        }
        path,
        polygon,
        rect {
            fill: $cc-green;
        }
    }

    // Bottom bar
    &-bottomBar {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        @include mq($viewport--md) {
            display: block;
        }

        &Wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    // Other elements
    .Container {
        height: 100%;
    }
}
