// Alert
.fui-alert {
    @extend .Alert;

    @each $modifier, $color in $theme-modifiers {
        $alert-color: color-level($color, 6);
        $alert-border: color-level($color, -9);

        &-#{$modifier} {
            color: $alert-color;
            background-color: color-level($color, -10);
            border-color: $alert-border;

            hr {
                border-bottom-color: darken($alert-border, 5%);
            }

            .Alert-link {
                color: darken($alert-color, 10%);
            }
        }
    }
}

// Error
.fui-error-message {
    @extend .Form-help;
    color: $form-error-color;
}

// Form
.fui-page-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.fui-row {
    display: flex;
    gap: 1.5rem 4.5rem;
    flex-wrap: wrap;
}

.formie-recaptcha-placeholder {
    //margin-top: u($spacing-unit);
    //margin-bottom: u($spacing-unit);
}

.fui-field {
    //padding-top: 2rem;
    //padding-left: 4.5rem;
    width: 100%;

    @include mq($from: $viewport--md) {
        flex: 1;
    }
}

.fui-input {
    @extend .Form-field;
    @extend .Form-field--input;

    .fui-type-multi-line-text & {
        @extend .Form-field--textarea;
    }

    // Error
    .fui-error & {
        border-bottom-color: $form-error-color !important;
    }

    // Success
    .fui-success & {
        border-bottom-color: $form-success-color !important;
    }
}

.fui-select {
    @extend .Form-field--select;
}

.fui-checkbox {
    @extend .Form-field;
    @extend .Form-field--checkbox;
}

.fui-radio {
    @extend .Form-field;
    @extend .Form-field--radio;
}

.fui-type-address {

    .fui-row + .fui-row {
        padding-top: 2rem;
    }
}

.fui-sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.fui-row-empty {

    .fui-type-hidden-field {
        padding-top: 0;
    }
}

.fui-legend {
    font-family: $font-saira;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .2rem;

    + .fui-instructions {
        margin-top: 1rem;
        opacity: 1;
        p {
            font-size: 1.6rem;
        }
    }
}
.fui-row:not(:first-child) {
    .fui-legend {
        padding-top: 1.5rem;
    }
}

.fui-instructions {
    margin-top: 2rem;
    opacity: .8;

    p {
        font-size: 1.2rem;
        margin-bottom: 0;
    }

    + .fui-layout-wrap {
        margin-top: 1.5rem;
    }
}

.fui-btn-container {
    margin-top: 4rem;
}

.fui-heading {
    @include mq($from: $viewport--md) {
        max-width: 58rem;
    }
}

/*
    "The Cleanest Trick for Autogrowing Textareas"
    https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
 */

.fui-type-multi-line-text {
    .fui-input-container {
        /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
        display: grid;
    }
    .fui-input-container::after {
        /* Note the weird space! Needed to preventy jumpy behavior */
        content: attr(data-replicated-value) " ";

        /* This is how textarea text behaves */
        white-space: pre-wrap;

        /* Hidden from view, clicks, and screen readers */
        visibility: hidden;
    }
    .fui-input-container textarea {
        /* You could leave this, but after a user resizes, then it ruins the auto sizing */
        resize: none;

        /* Firefox shows scrollbar on growth, you can hide like this. */
        overflow: hidden;
    }
    .fui-input-container textarea,
    .fui-input-container::after {
        /* Identical styling required!! */
        //border: 1px solid black;
        //padding: 0.5rem;
        //font: inherit;

        /* Place on top of each other */
        grid-area: 1 / 1 / 2 / 2;
    }
}

// Hide Google Invisible reCAPTCHA badge
.grecaptcha-badge {
    visibility: hidden;
}

// Add spacing above hCaptcha element
.formie-hcaptcha-placeholder {
    margin-top: 1.5rem;
}
