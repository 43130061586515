/* Logos */
.Logos {

}

.Logos-title {
    margin-bottom: 3rem;
    @include mq($viewport--md) {
        margin-bottom: 4rem;
    }
    @include mq($viewport--lg) {
        font-size: 5.5rem;
        margin-bottom: 5rem;
    }
}

.Logos-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin: -1.25rem;
    padding-left: 0;
    @include mq($viewport--md) {
        margin: -1.25rem -2rem;
    }
    @include mq($viewport--lg) {
        margin: -3rem -4rem;
    }
    @include mq($viewport--xl) {
        margin: -3rem -6rem;
    }
}

.Logos-item {
    display: flex;
    align-items: center;
    flex-basis: 50%;
    padding: 1.25rem;
    margin-bottom: 0;
    @include mq($viewport--xs) {
        flex-basis: 33.33%;
    }
    @include mq($viewport--md) {
        flex-basis: 25%;
        padding: 1.25rem 2rem;
    }
    @include mq($viewport--lg) {
        flex-basis: 20%;
        padding: 1.5rem 4rem;
    }
    @include mq($viewport--xl) {
        padding: 1.5rem 6rem;
    }

    img {
        filter: grayscale(100%);
        opacity: .75;
        transition: opacity .3s ease-in-out, filter .3s ease-in-out;
        max-height: 4rem;
        object-fit: contain;
        pointer-events: none;

        @include mq($viewport--sm) {
            max-height: 6rem;
        }
    }
}

a.Logos-item {
    &:hover, &:focus {
        img {
            opacity: 1;
            filter: grayscale(0);
        }
    }
}
