/* Newsletter Form */
.NewsletterForm {
    position: relative;

    .fui-i {
        display: flex;
        flex-direction: column-reverse;
    }

    .fui-row,
    .fui-field {
        margin: 0;
    }

    .fui-field {
        &.fui-text-input-field {
            input {
                padding: 1rem 3rem 1rem 1rem;
                height: 5rem;
                background-color: transparent;
                border-bottom-color: rgba($cc-grey--100, .4);
                color: $white;
            }

        }
    }

    .fui-error-message {
        padding: 0 1rem;
    }

    .fui-btn-container {
        margin-top: 0;
    }

    .Button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        width: 5rem;
        min-height: 4.9rem;
        padding: 1rem;
        background-color: $cc-grey--700;
        color: $white;

        &:hover,
        &:focus {
            background-color: lighten($cc-grey--700, 3%);
        }

        &-background {
            display: none;
        }

        &-icon {
            margin-right: 0 !important;
        }

        &-label {
            display: none;
        }
    }

    .Alert {
        border-radius: 0;
        border: none;
        font-style: italic;
        padding: 0;
        margin: 1.5rem 0 0;
    }
}

.NewsletterForm.NewsletterForm--alt {
    .Button {
        background-color: $cc-green;
        color: $cc-black;

        &:hover,
        &:focus {
            color: $white;
            background-color: $black;
        }
    }
}
