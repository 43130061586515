/* Block Tile */

.BlockTile {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
    grid-gap: 7rem;
    position: relative;
    z-index: 2;
    margin-top: 4rem;
    @include mq($viewport--lg) {
        grid-template-columns: minmax(auto, 50%) 1fr;
        grid-gap: 10rem;
        align-items: center;
    }
    @include mq($viewport--xl) {
        grid-template-columns: minmax(auto, 66rem) 1fr;
    }

    /* - cell */
    &-cell {
        position: relative;
        &:nth-child(1) {
            grid-row: 2;
            @include mq($viewport--lg) {
                grid-row: 1;
                grid-column: 2;
            }
        }
        &:nth-child(2) {
            grid-row: 1;
            @include mq($viewport--lg) {
                grid-column: 1;
            }
        }
    }

    /* -- body */
    &-body {
        @include mq($viewport--lg) {
            padding: 2rem 0;
        }
        @include mq($viewport--xl) {
            padding: 4rem 0;
        }
        @include mq(1400) {
            padding: 8rem 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    /* -- image */
    &-image {
        position: relative;
        max-width: 66rem;
        &:before {
            content: "";
            position: absolute;
            top: -4rem;
            right: 20rem;
            z-index: -1;
            width: 100vw;
            height: calc(100% + 8rem);
            background-color: $cc-green;
        }
        img {
            width: 100%;
        }
    }

    /* --- tagline */
    &-tagline {
        opacity: .5;
    }

    /* --- title */
    &-title {
        @include mq($viewport--sm) {
            max-width: 75%;
        }
        @include mq($viewport--lg) {
            max-width: none;
            margin-right: 4rem;
        }
        @include mq($viewport--xl) {
            margin-right: 8rem;
        }
    }

    /* --- text */
    &-text {
        margin-bottom: 2rem;
        @include mq($viewport--lg) {
            margin-bottom: 4rem;
        }
    }

    /* --- list */
    &-list {
        display: flex;
        flex-direction: column;
        margin: -1rem;
        padding-left: 0;
        @include mq($viewport--sm) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    &-listItem {
        position: relative;
        display: flex;
        padding: 1rem;
        margin-bottom: 0;
        > * {
            align-self: center;
        }
    }

    /* --- pixels */
    &-pixels {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        svg {
            position: relative;
            top: -1px;
            left: 1px;
            height: calc(100% + 2px);
            min-height: 40rem;
        }
        path,
        polygon,
        rect {
            fill: $white;
        }
        &.BlockTile-pixels--bg-white {
            path,
            polygon,
            rect {
                fill: $white;
            }
        }
        &.BlockTile-pixels--bg-grey-100 {
            path,
            polygon,
            rect {
                fill: $cc-grey--100;
            }
        }
        &.BlockTile-pixels--bg-grey-700 {
            path,
            polygon,
            rect {
                fill: $cc-grey--700;
            }
        }
        &.BlockTile-pixels--bg-black {
            path,
            polygon,
            rect {
                fill: $cc-black;
            }
        }
    }
}
