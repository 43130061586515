/* Dropdown */
.Dropdown {
    position: absolute;
    top: -1rem;
    left: -4rem;
    z-index: 1;
    width: calc(100% + 8rem);
    transform: translateY(-2rem);
    pointer-events: none;
    opacity: 0;
    transition: .15s transform ease-out,
                .1s opacity ease-out;

    @include mq($viewport--xl) {
        left: 0;
        width: 100%;
    }

    &:hover {
        transform: translateY(0);
        pointer-events: all;
        opacity: 1;

        transition: .3s transform ease-out .2s,
                    0s pointer-events linear .5s,
                    .15s opacity ease-out .2s;
    }

    /* Wrapper */

    &-wrapper {
        position: relative;
        margin-top: 20rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        background-color: $cc-grey--100;

        @include mq($viewport--xl) {
            margin-top: u($structure-header-height);
            flex-wrap: nowrap;
            gap: 0;
            padding: 0;
        }

        /*&:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 100vw;
            height: 100%;
            background-color: $cc-grey--100;
        }
        &:before {
            left: 50%;
        }
        &:after {
            right: 50%;
        }*/

        > * {
            position: relative;
            z-index: 1;
        }
    }

    /* Inner elements */

    &-item {
        display: block;
        text-decoration: none;
        color: currentColor;
        position: relative;
        z-index: 3;
        flex: 0 0 100%;
        transition: .4s color ease;
        @include mq($viewport--xl) {
            flex-grow: 1;
            flex-basis: 25%;
            padding: 4.5rem 4.5rem 6rem;
        }
        .Dropdown-title,
        .Dropdown-text {
            transition: .4s color ease;
        }
    }
    a.Dropdown-item {
        > * {
            cursor: pointer;
        }
    }
    a.Dropdown-item:hover,
    a.Dropdown-item:focus {
        color: $white;
        .Dropdown-title {
            color: $cc-green;
        }
        .Dropdown-text {
            color: $white;
        }
    }
    .Dropdown-item.is-active {
        @include mq($viewport--xl) {
            color: $white;
            .Dropdown-title {
                color: $cc-green;
            }
            .Dropdown-text {
                color: $white;
            }
        }
    }

    &-subItems {
        position: relative;
        z-index: 3;
        flex: 1 1 100%;
        padding: 4rem;
        @include mq($viewport--lg) {
            flex-basis: 60%;
            padding-bottom: 6rem;
        }
        @include mq($viewport--xl) {
            flex-basis: 75%;
            padding: 4.5rem;
        }
        .Dropdown-title {
            margin-bottom: 2.75rem;
        }
    }

    &-title {
        display: block;
        cursor: default;
    }

    &-text {
        cursor: default;

        &:last-child > *:last-child {
            margin-bottom: 0;
        }
    }

    &-button {

    }

    &-links {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-gap: 1.75rem;

        li {
            margin-bottom: 0;
        }
        a {
            display: block;
            color: currentColor;
            text-decoration: none;
            &:hover,
            &:focus {
                span span {
                    transform: translateX(.3rem);
                }
            }
            > span {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: center;
            }
        }
        span {
            span {
                font-family: $font-saira;
                font-size: 1.4rem;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: .2rem;
                transition: .2s transform ease .1s;
            }
        }
        p {
            margin-bottom: 0;
        }
    }

    &-icon {
        position: relative;
        top: -.1rem;
        display: block;
        height: 1.25rem;
        width: 1.25rem;
        margin-right: .75rem;
    }

    &-hover {
        display: none;
        position: absolute;
        width: 100%;
        height: 10rem;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: $cc-grey--700;
        transition: .375s transform cubic-bezier(0.540, 0.085, 0.475, 1.190) .025s;

        @include mq($viewport--xl) {
            display: block;
            width: 25%;
            height: 100%;
        }
    }

    /* Modifiers */

    &.Dropdown--typeA {
        .Dropdown-wrapper {
            @include mq($until: $viewport--xl - 1) {
                padding: 2rem 4rem;
                gap: 4rem;
            }
        }
        .Dropdown-item {
            &:before {
                content: "";
                position: absolute;
                top: -2rem;
                left: -4rem;
                z-index: -1;
                height: calc(100% + 4rem);
                width: calc(100% + 8rem);
                background-color: transparent;
                pointer-events: none;
                transition: .125s background-color ease-in;
                @include mq($viewport--xl) {
                    content: none;
                }
            }
            &:hover,
            &:focus {
                &:before {
                    background-color: $cc-grey--700;
                }
            }
        }
        .Dropdown-title,
        .Dropdown-text {
            max-width: 75%;
            @include mq($viewport--xl) {
                max-width: 100%;
            }
        }
        .Dropdown-button {
            background-color: transparent;
            padding-left: 0;
            transition: .1s background-color ease-in,
                        .25s color ease-in,
                        .15s padding-left ease-in .1s;
        }
    }
    &.Dropdown--typeB {
        .Dropdown-wrapper {
            gap: 0;
        }
        .Dropdown-item {
            background-color: $cc-grey--700;
            color: $white;
            padding: 4rem 4rem 6rem;
            flex-basis: 100%;

            @include mq($viewport--lg) {
                flex-basis: 40%;
            }
            @include mq($viewport--xl) {
                flex-basis: 25%;
                padding: 4.5rem 4.5rem 6rem;
            }
            .Dropdown-title {
                color: $cc-green;
            }
        }
    }

    /* Other elements */

    .Container {
        padding: 0;
    }

    + .Backdrop {
        position: absolute;
        top: 100%;
        left: -4500px;
    }
}

/* States */

.is-active {
    .Dropdown-item,
    .Dropdown-title,
    .Dropdown-text {
        transition: .3s color cubic-bezier(.09, .2, .46, .81) .125s;
    }
}
.Dropdown--typeA {
    .is-active {
        .Dropdown-button {
            background-color: $cc-green;
            padding-left: $button-padding--l;

            transition: .25s background-color ease-in .25s,
            .25s color ease-in,
            .15s padding-left ease-in;
        }
    }
}
