/* Footer bottom navigation */
.Nav--footerSecondary {

    // List
    .Nav-list {
        flex-direction: column;
        flex-wrap: wrap;
        gap: .5rem;
        @include mq($viewport--xs) {
            flex-direction: row;
            gap: .75rem 1.5rem;
        }
        @include mq($viewport--lg) {
            gap: .75rem 2.5rem;
        }
        @include mq($viewport--xl) {
            gap: .75rem 3.5rem;
        }
    }

    // Item
    .Nav-item {

    }

    // Link
    .Nav-link {
        color: $white;
        font-size: 1.4rem;
        font-weight: 400;
        opacity: .45;

        &:hover, &:focus {
            opacity: .8;
        }
    }
}
