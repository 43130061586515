/* Blocks */
.Block {
    position: relative;
    z-index: 1;
    padding: 5rem 0;

    @include mq($from: $viewport--md) {
        padding: 10rem 0;
    }
    @include mq($from: $viewport--xl) {
        padding: 13rem 0;
    }

    // Modifiers
    // - backgrounds
    @each $modifier, $color in $theme-modifiers {
        &.Block--bg-#{$modifier} {
            // Variables
            --block-background-color: #{$color};
            @if ($modifier == "grey-700") or ($modifier == "black") {
                --block-text-color: #{$white};
            } @else {
                --block-text-color: #{$black};
            }

            background-color: $color;
            color: var(--block-text-color, #{$text-color});

            // Section spacing!
            // - mobile spacing
            + .Block--bg-#{$modifier} {
                @include mq($until: $viewport--md - 1) {
                    padding-top: 0 !important;
                }
            }
            // - desktop spacing
            &:not(.Block--noPadding) + .Block--bg-#{$modifier} {
                @include mq($from: $viewport--md) {
                    padding-top: 0 !important;
                }
            }
        }
    }
    // - spacing
    &.Block--noPadding {
        @include mq($from: $viewport--md) {
            padding: 0;
        }
    }
    &.Block--morePadding {
        @include mq($from: $viewport--xl) {
            padding: 20rem 0;
        }
    }
    // - pixels
    &.Block--pixels {
        .Pixels {
            bottom: -5rem;
            @include mq($from: $viewport--lg) {
                bottom: -10rem;
            }
        }
        + .Block {
            //padding-top: 0;
        }
    }
    // - types
    &.Block--box {
        z-index: 2;
        &.has-media + .Block:not(.Block--box) {
            //margin-top: 4rem;
            @include mq($from: $viewport--md) {
                margin-top: 10rem;
            }
            &:before {
                content: "";
                position: absolute;
                top: -4rem;
                left: 0;
                height: 4rem;
                width: 100%;
                @include mq($from: $viewport--md) {
                    top: -10rem;
                    height: 10rem;
                }
            }
            @each $modifier, $color in $theme-modifiers {
                &.Block--bg-#{$modifier} {
                    &:before {
                        background-color: $color;
                    }
                }
            }
        }
    }
    &.Block--newsDetail {
        .Breadcrumbs {
            position: relative;
            top: -2.5rem;
            @include mq($from: $viewport--md) {
                position: absolute;
                top: -9rem;
            }
            @include mq($from: $viewport--xl) {
                top: -12rem;
            }
        }
    }
    &.Block--clients {
        //margin-top: 5rem;
    }
    &.Block--box {
        &:last-child {
            margin-bottom: 2.5rem;

            @include mq($from: $viewport--md) {
                margin-bottom: 13rem;
            }
            @include mq($viewport--lg) {
                margin-bottom: 15rem;
            }
            @include mq($viewport--xl) {
                margin-bottom: 20rem;
            }
        }
    }
    &.Block--textSlider {
        z-index: 2;
    }
    &.Block--box,
    &.Block--bigQuote,
    &.Block--banner {
        @include mq($until: $viewport--md - 1) {
            padding: 0;
        }
    }

    // Block ID link
    > a[id] {
        display: block;
        position: relative;
        top: -5rem;
        @include mq($from: $viewport--md) {
            top: -10rem;
        }
    }
    &.Block--noPadding {
        > a[id] {
            top: 0;
        }
    }
    &.Block--columnItems {
        &:not(:first-child) {
            @include mq($from: $viewport--md) {
                margin-top: -5rem;
                padding-bottom: 5rem;
            }
            @include mq($from: $viewport--xl) {
                margin-top: -6.5rem;
                padding-bottom: 6.5rem;
            }
        }
    }
}
