/* Bit */

.Bit {
    text-align: center;

    &-title {
        @include mq($viewport--sm) {
            font-size: u($heading-size-2);
        }
        @include mq($viewport--lg) {
            font-size: 5.5rem;
            margin-bottom: 5rem;
        }
        strong {
            font-weight: 300;
            color: $cc-green;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
