/* Link */

.Link {
    display: inline-flex;
    align-items: center;
    gap: .75rem;
    font-family: $font-saira;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.25;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .2rem;
    color: $cc-black;

    &-icon {
        display: block;
        height: .8rem;
        width: .8rem;
        position: relative;
        top: -.1rem;

        path,
        polygon,
        rect {
            fill: currentColor;
        }
    }

    &-label {
        transition: .3s transform ease .1s;
    }

    &:hover,
    &:focus {
        color: $cc-black;

        .Link-label {
            transform: translateX(.5rem);
        }
    }

    @each $modifier, $color in $theme-modifiers {
        @if ($modifier != 'black') {
            &.Link--#{$modifier} {
                color: $color;
            }
        }
    }
}

span.Link {
    cursor: default;
}
