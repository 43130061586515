/*
    Filters
 */

.Filter {

}

.Filter-items {
    display: flex;
    flex-direction: column;
    gap: 1.75rem 2rem;
    color: $cc-grey--700;

    @include mq($from: 320px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    @include mq($from: $viewport--md) {
        flex-direction: row;
    }
}

.Filter-item {
    display: block;
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1;
    color: inherit;
    text-align: center;
    text-decoration: none;

    @include mq($from: 320px) {
        text-align: left;
    }

    .Breadcrumbs-icon {
        height: 1rem;
        width: 1.1rem;
    }
}

.Filter-link {
    display: block;
    font-family: $font-saira;
    font-size: 1.3rem;
    font-weight: 600;
    color: $cc-black;
    text-transform: uppercase;
    letter-spacing: .2rem;
    text-decoration-color: $cc-green;
    text-decoration-thickness: 2px;
    text-decoration-line: none;
    text-underline-offset: .5rem;
    opacity: .65;

    &:hover,
    &:focus {
        color: $cc-black;
        opacity: 1;
    }

    &.is-active {
        text-decoration-line: underline;
        opacity: 1;
    }
}
