/*
    Header
 */

.Header {
    background-color: $cc-grey--100;
    padding: 3rem 0;
    width: 100%;

    &:before {
        @include mq(920) {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $cc-grey--100;
            pointer-events: none;
        }
    }

    @include mq($from: 920) {
        padding: 2rem 0 4rem;
    }
    @include mq($from: $viewport--lg) {

    }
}

.Header-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    @include mq($from: $viewport--md) {

    }
    @include mq($from: $viewport--lg) {
        align-items: flex-end;
        gap: 2rem;
    }
    @include mq($from: $viewport--xl) {
        gap: 4rem;
    }
}

.Header-item {
    &:first-child {
        @include mq($from: $viewport--xl) {
            position: relative;
            flex-basis: 17rem;
        }
    }
    &:last-child {
        display: flex;
        flex: 1 1 auto;
    }
}

.Header-button {
    display: none;
    z-index: 2;
    @include mq(920) {
        display: inline-flex;
    }
}

.Header-siteOverlay {
    margin-left: auto;
    @include mq(920) {
        display: none;
    }
}

.Header .Logo {
    position: relative;
    z-index: 2;
    @include mq(920) {
        position: absolute;
        bottom: calc(50% - 2.2rem);
        left: 0;
    }
    @include mq($from: $viewport--xl) {
        bottom: .25rem;
    }
}

.Header.Header--alt {
    background-color: $cc-black;
    &:before {
        background-color: $cc-black;
    }
    .Logo path {
        fill: $cc-grey--100;
    }
    .Nav--headerPrimary .Nav-link {
        color: $cc-grey--100;
    }
    .Nav--headerSecondary .Nav-link,
    .Nav--lang .Nav-toggle span,
    .Nav--lang .Nav-link,
    .Nav--lang .Nav-icon {
        color: $white;
    }
    .Nav--lang .Nav-list {
        background-color: $cc-black;
    }
}

.Header.Header--alt.Header--absolute {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    &:before {
        content: none;
    }
    .Backdrop {
        display: none !important;
    }
}
