/* Tiles */

.Tiles {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    @include mq($from: $viewport--md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include mq($from: $viewport--lg) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.Tiles-item {
    background: $white;
    padding: 2rem;
    display: flex;

    &:before {
        @include mq($from: $viewport--md) {
            content: '';
            float: left;
            padding-bottom: 100%;
        }
    }

    @include mq($from: $viewport--md) {
        padding: 4rem;
    }

    &Wrapper {
        display: flex;
        flex-direction: column;
        &Top {
            padding-bottom: 5rem;
        }
        &Bottom {
            margin-top: auto;
        }
    }
    &Title,
    &Subtitle {
        &:last-child {
            margin-bottom: 0;
        }
    }
    &Title {
        color: $black;
        margin-bottom: .5rem;
    }
    &Subtitle {
        font-size: 1.7rem;
        color: $cc-grey--700;
        span {
            position: relative;
            + span {
                &:before {
                    content: " | "
                }
            }
        }
    }
    &Label {

    }
}

a.Tiles-item {
    text-decoration: none;
    &:hover,
    &:focus {
        background-color: $cc-grey--300;
    }
}

.Tiles .Brick {
    &:before {
        @include mq($from: $viewport--md) {
            content: '';
            float: left;
            padding-bottom: 100%;
        }
    }
}
