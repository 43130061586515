/* Form */

// Item
.#{$form-namespace}Form-item {

    &:not(.#{$form-namespace}Form-item--action) {
        margin-bottom: u($form-item-spacing);
    }
}

// Floating label
.has-float {
    position: relative;

    .fui-input-container {
        position: relative;

        ::placeholder {
            color:    transparent;
        }
        ::-webkit-input-placeholder {
            color:    transparent;
        }
        :-moz-placeholder {
            color:    transparent;
        }
        ::-moz-placeholder {
            color:    transparent;
        }
    }

    .fui-input {
        //padding-top: u($form-element-padding--t);
        //padding-bottom: u($form-element-padding--t);
    }

    .fui-label {
        @include truncate();
        @include transition(#{opacity, font-size, transform}, .1s);
        @include position(absolute, $right: u($form-element-padding--r), $left: u($form-element-padding--l));
        line-height: 1;
        pointer-events: none;
        overflow: visible;
        transform: translateY(1.75rem);
        transform-origin: top left;
        z-index: 2;
        opacity: .8;
    }

    &.is-active .fui-label {
        font-size: 1rem;
        transform: translateY(0);
        opacity: .65;
    }
}
.has-float .fui-label,
.Form-field--file:not(.has-file) > .Form-indicator {
    //color: $cc-grey--700;
}

// Label
.#{$form-namespace}Form-label {
    display: inline-block;
    margin-bottom: u($form-label-spacing--b);

    // Style label if it's a optional item
    .#{$form-namespace}Form-item:not(.is-required) &[data-optional]::after {
        content: attr(data-optional);
        font-size: 65%;
        font-style: italic;
        margin-left: u($spacing-unit--xs);
        text-transform: lowercase;
    }

    // Error
    .#{$form-namespace}Form-item.has-error & {
        color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        color: $form-success-color;
    }
}

// Help
.#{$form-namespace}Form-help {
    /*position: absolute;
    top: 100%;
    left: 0;
    width: 100%;*/
    color: $form-help-color;
    font-size: u($form-help-font-size);
    font-weight: 600;
    margin-top: 1rem;

    &:first-child {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    & + & {
        margin-top: 1rem;
        /*padding-top:  1rem;
        border-top: .1rem solid $form-divider-color;*/
    }
}

// Validation
.#{$form-namespace}Form-help--validation {

    // Error
    .#{$form-namespace}Form-item.has-error & {
        color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        color: $form-success-color;
    }
}

// Controls
.#{$form-namespace}Form-controls {
    position: relative;
}

// Horizontal
@include mq($from: $viewport--sm) {
    .#{$form-namespace}Form--horizontal {

        .#{$form-namespace}Form-item {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }

        // Label
        .#{$form-namespace}Form-label {
            margin-top: u($form-label-spacing--t);
            margin-bottom: 0;
            padding-right: u($spacing-unit);
            width: percentage($form-label-width);
        }

        // Controls
        .#{$form-namespace}Form-controls {
            width: percentage($form-controls-width);
        }

        // Actions
        .#{$form-namespace}Form-item--action {
            padding-left: percentage($form-label-width);
        }
    }
}

// Black background
/*.Block--bg-black {
    .#{$form-namespace}Form-field--input,
    .#{$form-namespace}Form-field--textarea {
        background-color: $black;
        color: $white;
        border-bottom-color: $white;
    }
    .Form-field--select, .fui-select {
        background-color: transparent;
        border-bottom-color: $white;
    }
    .has-float .fui-label,
    .Form-field--file:not(.has-file) > .Form-indicator,
    .fui-instructions p {
        color: $white;
    }
    .Form-field--radio .Form-indicator, .fui-radio .Form-indicator, .Form-field--checkbox .Form-indicator, .fui-checkbox .Form-indicator {
        background-color: transparent;
        border-color: $white;
        &:checked ~ .#{$form-namespace}Form-indicator {
            background-color: transparent;
            border-color: $white;
        }
    }
}*/
