/* Alt Hero */

.AltHero {
    position: relative;
    padding: 1rem 0 3rem;
    background-color: $cc-grey--100;
    @include mq($viewport--md) {
        padding-bottom: 8rem;
    }
    @include mq($viewport--xl) {
        padding-bottom: 13rem;
    }

    // Hornet
    &-hornet {
        margin-bottom: 6rem;
    }

    // - title
    &-title {
        font-weight: 500;
        margin-bottom: 0;
    }

    // Wrapper
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 4rem;
        @include mq($viewport--md) {
            flex-direction: row-reverse;
            gap: 8rem;
        }
        @include mq($viewport--xl) {
            gap: 13rem;
        }
    }

    // Body
    &-body {
        flex: 1 1 50%;
    }

    // - subtitle
    &-subtitle {
        margin-bottom: 3rem;
    }

    // - form
    &-form {

        .Form-field,
        .fui-input,
        .fui-checkbox,
        .fui-radio,
        .Form-field--textarea,
        .fui-type-multi-line-text .fui-input,
        .Form-field--radio .Form-indicator,
        .fui-radio .Form-indicator,
        .Form-field--checkbox .Form-indicator,
        .fui-checkbox .Form-indicator {
            background-color: transparent;
        }
    }

    // Media
    &-media {
        position: relative;
        flex: 1 1 50%;
        picture {
            @include mq($viewport--md) {
                height: 100%;
            }
            &:before {
                @include mq($viewport--md) {
                    content: none;
                }
            }
        }
        img {
            object-fit: cover;
        }
    }


    // Bottom bar
    &-bottomBar {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        @include mq($viewport--md) {
            display: block;
        }

        &Wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    // Other elements
    .Breadcrumbs {
        margin-bottom: 3.5rem;
    }
}
