/* Settings: Fonts */

/*
    Font import

    Open-Sans
     - 300 (light)
     - 300 italic
     - 400 (regular)
     - 400 italic
     - 700 (medium)
     - 700 italic
 */

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/OpenSans-Light.woff2') format('woff2'),
         url('../fonts/open-sans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/OpenSans-LightItalic.woff2') format('woff2'),
         url('../fonts/open-sans/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/OpenSans-Regular.woff2') format('woff2'),
         url('../fonts/open-sans/OpenSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/OpenSans-Italic.woff2') format('woff2'),
         url('../fonts/open-sans/OpenSans-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/OpenSans-Bold.woff2') format('woff2'),
         url('../fonts/open-sans/OpenSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/OpenSans-BoldItalic.woff2') format('woff2'),
         url('../fonts/open-sans/OpenSans-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

/*
    Font import
    Saira
     - 300 (light)
     - 300 italic
     - 400 (regular)
     - 400 italic
     - 500 (medium)
     - 500 italic
 */

@font-face {
    font-family: 'Saira';
    src: url('../fonts/saira/Saira-Light.woff2') format('woff2'),
         url('../fonts/saira/Saira-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Saira';
    src: url('../fonts/saira/Saira-LightItalic.woff2') format('woff2'),
         url('../fonts/saira/Saira-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Saira';
    src: url('../fonts/saira/Saira-Regular.woff2') format('woff2'),
         url('../fonts/saira/Saira-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Saira';
    src: url('../fonts/saira/Saira-Italic.woff2') format('woff2'),
         url('../fonts/saira/Saira-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Saira';
    src: url('../fonts/saira/Saira-Medium.woff2') format('woff2'),
         url('../fonts/saira/Saira-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Saira';
    src: url('../fonts/saira/Saira-MediumItalic.woff2') format('woff2'),
         url('../fonts/saira/Saira-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Saira';
    src: url('../fonts/saira/Saira-SemiBold.woff2') format('woff2'),
    url('../fonts/saira/Saira-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Saira';
    src: url('../fonts/saira/Saira-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/saira/Saira-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

$font-open-sans:                        'Open Sans', serif !default;
$font-saira:                            'Saira', serif !default;
$font-mono:                             SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
