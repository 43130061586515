/* Lang */
.Nav--lang {
    display: none;
    position: relative;

    @include mq(920) {
        display: block;
    }

    // Toggle
    .Nav-toggle {
        position: relative;
        z-index: 2;
        cursor: default;
        display: flex;
        align-items: center;
        margin: -.25rem;;
        opacity: .65;

        span,
        svg {
            margin: .25rem;
        }
        span {
            font-family: $font-saira;
            font-size: 1.3rem;
            font-weight: 400;
            color: $cc-black
        }

        &:hover {
            + .Nav-list {
                opacity: 1;
                pointer-events: all;
                transform: translateY(0);
                transition: .15s opacity ease-out .2s,
                            0s pointer-events linear .5s,
                            .2s transform ease-out .1s;
            }
        }
    }

    // Icon
    .Nav-icon {
        display: block;
        height: .6rem;
        width: .6rem;
        color: $cc-black;

        path {
            fill: currentColor;
        }
    }

    // List
    .Nav-list {
        flex-direction: column;
        background-color: $cc-grey--100;
        padding: 1rem;
        position: absolute;
        top: 100%;
        left: -1rem;
        width: calc(100% + 2rem);
        z-index: 2;
        transform: translateY(-2rem);
        pointer-events: none;
        opacity: 0;
        transition: .1s opacity ease-out,
                    .15s transform ease-out;

        &:hover {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
        }
    }

    // Link
    .Nav-link {
        position: relative;
        z-index: 2;
        font-family: $font-saira;
        font-size: 1.3rem;
        font-weight: 400;
        color: currentColor;
        opacity: .65;

        &:focus,
        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}
