/* Block Article */

.BlockArticle {

}

// Header
.BlockArticle-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 3rem;
    @include mq($from: $viewport--md) {
        margin-bottom: 4rem;
    }
    @include mq($from: $viewport--lg) {
        flex-direction: row;
        align-items: center;
        gap: 4rem;
        margin-bottom: 7rem;
    }
}

// - item
.BlockArticle-headerItem {

    &:nth-child(1) {
        @include mq($from: $viewport--lg) {
            flex-shrink: 1;
        }
    }
    &:nth-child(2) {
        @include mq($from: $viewport--lg) {
            margin-left: auto;
        }
    }
    &:only-child {
        @include mq($from: $viewport--lg) {
            max-width: 75%;
        }
    }
}

// - label
.BlockArticle-label {
    display: flex;
    flex-wrap: wrap;
    gap: .75rem 1.5rem;
    margin-bottom: .5rem;
    span {
        @include font-size($heading-size-5, $heading-spacing-5);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .2rem;
    }
}

// - title
.BlockArticle-title {
    margin-bottom: 0;
    @include mq($from: $viewport--lg) {
        word-break: break-word;
    }
}

// - author
.BlockArticle-author {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 1.5rem;
    @include mq($from: $viewport--lg) {
        gap: 2.5rem;
    }
}

.BlockArticle-authorItem {

}
.BlockArticle-authorTitle {
    margin-bottom: 0;
}
.BlockArticle-authorDate {
    font-size: 1.4rem;
    margin-bottom: 0;
    @include mq($from: $viewport--md) {
        white-space: nowrap;
    }
}
.BlockArticle-authorImage {
    display: block;
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    overflow: hidden;
}

// Image
.BlockArticle-image {
    background-color: $cc-grey--400;
}

// Content
.BlockArticle-content {
    padding: 3rem 4rem;
    background-color: $white;

    @include mq($from: $viewport--md) {
        padding: 4rem 6rem;
    }
    @include mq($from: $viewport--lg) {
        padding: 7.5rem 11.5rem;
    }
}
