/* Intro */

.Intro {
    padding: 5rem 0;

    @include mq($from: $viewport--md) {
        padding: 10rem 0;
    }
    @include mq($from: $viewport--xl) {
        padding: 13rem 0;
    }

    // Hornet
    &-hornet {
        position: relative;
        .Breadcrumbs {
            position: absolute;
            top: -4rem;
            left: 0;

            @include mq($from: $viewport--md) {
                top: -9rem;
            }
            @include mq($from: $viewport--xl) {
                top: -12rem;
            }

        }
    }

    // Wrapper
    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 1rem;
        width: 85%;
        max-width: 100rem;
        margin: 0 auto;

        @include mq($from: $viewport--md) {
            width: 75%;
        }
        @include mq($from: $viewport--xl) {
            gap: 1.5rem;
        }
    }

    // - tagline
    &-tagline,
    &-title {
        margin-bottom: 0;
    }

    &-tagline {
        opacity: .5;
    }

    // - title
    &-title {
        position: relative;
        z-index: 2;
        font-weight: 300;
        margin-bottom: 0;
        @include mq($from: $viewport--xl) {
            font-size: 6rem;
        }
        strong {
            font-weight: normal;
            span {
                display: inline-block;
                font-weight: 300;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0.5rem;
                    left: -0.75rem;
                    height: calc(100% - 1rem);
                    width: calc(100% + 1.5rem);
                    background-color: $cc-green;
                }
            }
        }
    }

    // Modifiers
    // - backgrounds
    @each $modifier, $color in $theme-modifiers {
        &.Intro--bg-#{$modifier} {
            background-color: $color;

            // Section spacing!
            + .Block.Block--bg-#{$modifier} {
                padding-top: 0;
            }
        }
    }

    // Other elements
    + .Filter {
        background-color: $cc-grey--100;
        margin-top: -1.5rem;
        @include mq($from: $viewport--md) {
            position: absolute;
            z-index: 2;
            width: 100%;
            margin-top: -6rem;
        }
        + .Block.Block--overview {
            @include mq($from: $viewport--md) {
                margin-top: -1.5rem;
            }
        }
    }
}
