/* Block Slider */

.BlockSlider {
    overflow: visible !important;

    /* - slider items */
    &-items {

    }

    /* - slide */
    &-item {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-gap: 2.5rem;
        position: relative;
        z-index: 2;
        @include mq(980) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5rem;
            align-items: center;
        }
        @include mq($viewport--lg) {
            grid-template-columns: 1fr minmax(auto, 54rem);
            grid-gap: 10rem;
        }
        > * {
            box-sizing: border-box;
        }
    }

    /* - cell */
    &-cell {
        position: relative;
    }

    /* -- body */
    &-body {
        //padding-bottom: 2.5rem;

        @include mq(1200) {
            padding-left: 10rem;
            //padding-bottom: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    /* -- image */
    &-image {
        @include mq($viewport--md) {
            pointer-events: none;
            margin: -5rem 0;
        }
        @include mq($from: $viewport--xl) {
            margin: -6.5rem 0
        }
        picture {
            &:before {
                @include mq($until: $viewport--md - 1) {
                    content: none;
                }
            }
        }
        img {
            width: 100%;
            max-width: 54rem;
            @include mq($until: $viewport--md - 1) {
                position: static !important;
                max-width: 40rem;
            }
        }
    }

    /* --- tagline */
    &-tagline {
        display: block;
        margin-bottom: .5rem;
        opacity: .65;
    }

    /* --- title */
    &-title {
        @include mq($viewport--lg) {
            font-size: 5.5rem;
            margin-bottom: 4rem;
        }
    }

    /* --- text */
    &-text {
        margin-bottom: 2rem;
        @include mq($viewport--lg) {
            margin-bottom: 4rem;
        }
    }

}

.BlockSliderNav {
    position: relative;
    top: 2.5rem;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
    width: 100%;

    @include mq($from: $viewport--md) {
        position: absolute;
        top: auto;
        bottom: -13rem;
        right: 0;
        padding-right: 4rem;
        padding-left: 4rem;
    }
    @include mq($from: $viewport--xl) {
        bottom: -16rem;
    }

    &-button {
        justify-content: center;
        align-items: center;
        display: flex;
        height: 4rem;
        width: 4rem;
        color: $cc-black;
        background-color: $cc-green;
        transition: background-color .2s ease;
        @include mq($from: $viewport--md) {
            height: 6rem;
            width: 6rem;
        }
        &:hover,
        &:focus,
        &.is-hover,
        &.is-focus,
        &.is-active {
            background-color: $cc-black;
            color: $white;
        }
        svg {
            height: 1.25rem;
            width: 1.25rem;
            @include mq($from: $viewport--md) {
                height: 1.4rem;
                width: 1.4rem;
            }
        }
        path,
        polygon,
        rect {
            fill: currentColor;
            transition: .2s fill ease-out;
        }
        &--prev {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

/*
    Animations
 */

.BlockSlider {
    &-tagline,
    &-title,
    &-text,
    &-button {
        opacity: 0;
    }
    &-tagline {
        transition: opacity .275s ease-in .1s;
    }
    &-title {
        transition: opacity .275s ease-in .2s;
    }
    &-text {
        transition: opacity .275s ease-in .3s;
    }
    &-button {
        transition: opacity .275s ease-in .4s;
    }
    &-image {
        img {
            transition: height .325s cubic-bezier(.3,.78,.24,.47) .15s,
                        top .325s cubic-bezier(.3,.78,.24,.47) .15s;
        }
    }
}

.BlockSlider-item.is-active {
    .BlockSlider {
        &-title,
        &-text,
        &-button {
            opacity: 1;
        }
        &-tagline {
            opacity: .65;
        }
    }
}

.BlockSlider-item.is-prev {
    position: relative;
    @include mq($from: $viewport--xl) {
        left: 130px;
    }
    .BlockSlider-image {
        img {
            @include mq($from: $viewport--md) {
                height: 60%;
                top: 20%;
            }
        }
    }
}

// Dark background
.Block--bg-grey-700,
.Block--bg-black {
    .BlockSlider {
        &-tagline {
            color: $cc-grey--100;
        }
        &-title {
            color: $cc-green;
        }
    }
}
