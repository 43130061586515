/* Card */

.Card {
    display: block;
    width: 100%;
    text-decoration: none;
    position: relative;
    color: $cc-black;

    &-image,
    &-placeholder {
        position: relative;
        margin-bottom: 2.5rem;
        background-color: $cc-grey--300;
        overflow: hidden;
    }
    &-images {
        position: relative;
    }
    &-image {
        img {
            transition: .15s transform cubic-bezier(.4,.59,.54,.97) .1s;
        }
    }
    &-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-placeholder {

        // Ratios
        @each $ratio-name, $ratio-value in $RATIOS {
            @each $antecedent, $consequent in $ratio-value {
                @if (type-of($antecedent) != number) {
                    @error "`#{$antecedent}` needs to be a number.";
                }
                @if (type-of($consequent) != number) {
                    @error "`#{$consequent}` needs to be a number.";
                }
                &[data-ratio="#{$antecedent}:#{$consequent}"] {
                    padding-bottom: ($consequent/$antecedent) * 100%;
                }
            }
        }
    }

    &-pixels {
        position: absolute;
        bottom: -.1rem;
        left: 0;
        width: 100%;
        transform: translateY(100%);
        overflow: hidden;

        svg {
            position: relative;
            left: -2px;
            width: calc(100% + 4px);
        }

        &.Card-pixels--bg-white {
            path,
            polygon,
            rect {
                fill: $white;
            }
        }
        &.Card-pixels--bg-grey-100 {
            path,
            polygon,
            rect {
                fill: $cc-grey--100;
            }
        }
        &.Card-pixels--bg-grey-700 {
            path,
            polygon,
            rect {
                fill: $cc-grey--700;
            }
        }
        &.Card-pixels--bg-black {
            path,
            polygon,
            rect {
                fill: $cc-black;
            }
        }
    }

    &-body {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    /*
        Inner elements:
        - label
        - title
     */

    // Label
    &-label {
        display: flex;
        flex-wrap: wrap;
        gap: .125rem 1rem;
        margin-bottom: .5rem;
        @include mq($viewport--lg) {
            gap: .25rem 1.5rem;
        }
        span {
            font-size: 1.2rem;
            font-weight: 600;
            color: $cc-grey--700;
            opacity: .65;
            text-transform: uppercase;
            letter-spacing: .2rem;
            @include mq($viewport--lg) {
                @include font-size($heading-size-5, $heading-spacing-5);
            }
        }
    }

    // Tagline
    &-tagline {
        display: block;
        margin-bottom: .5rem;
        opacity: .65;
    }
    // Title
    &-title {

    }
    // Text
    &-text {
        font-size: 1.4rem;
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &:hover,
    &:focus {
        color: currentColor;

        .Card-image {
            img {
                transform: scale(1.05);
            }
        }
        .Card-pixels {
            animation: touchdown .15s steps(3, end) forwards;
        }
    }

    // Modifiers
    &--slim {
        .Card-image,
        .Card-placeholder{
            margin-bottom: 1.25rem;
        }
        .Card-title {
            margin-bottom: .75rem;
        }
    }
    &--columnItem {
        .Card-image, .Card-placeholder {
            margin-bottom: 0;
        }
        &:not([href]) {
            .Card-image {
                img {
                    transform: none;
                }
            }
        }
        &:hover,
        &:focus {
            .Link {
                .Link-label {
                    transform: translateX(0.5rem);
                }
            }
        }
        .Card-body {
            padding: 4rem 4.5rem;
            background-color: $white;
            &--hasLink {
                padding-bottom: 10rem;
                .Card-link {
                    position: absolute;
                    bottom: 4rem;
                }
            }
        }
        .Card-title {
            font-size: 2.6rem;
        }
        .Card-text {
            font-size: 1.6rem;
            color: $cc-grey--700;
        }
        &:has(.Card-images) .Card-text {
            font-size: 1.3rem;
        }
        .Card-link {
            margin-top: 3rem;
            .Link-icon {
                top: 0
            }

        }
    }
}

.Block.Block--bg-grey-700,
.Block.Block--bg-black {

    .Card {
        color: $white;
        .Card-label span {
            color: $cc-grey--100;
        }
        &.Card--columnItem {
            .Card-title {
                color: $cc-black;
            }
        }
    }
}
.Block.Block--bg-white {
    .Card.Card--columnItem {
        background-color: $cc-grey--100;
        .Card-body {
            background-color: $cc-grey--100;
        }
    }
}


