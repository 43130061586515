/* Selection */
::selection {
    color: $selection-color;
    background-color: $selection-background-color;
}

.Hero,
.Block--bg-black,
.Block--bg-grey-700,
.Footer {
    ::selection {
        color: $white;
        background-color: rgba($cc-green, .8);
    }
}
