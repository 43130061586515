/* Block Image */

.BlockImage {
    height: 45vw;
    max-height: 50rem;
    picture {
        height: 100%;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
