/* Slider: scrollbar */
.Slider-scrollbar {
    background-color: rgba($cc-black, 0.1);
    border-radius: u(10);
    position: relative;
    -ms-touch-action: none;

    .Slider--horizontal > & {
        @include dimensions(98%, 5);
        @include position(absolute, $bottom: u(3), $left: 1%, $z: 50);
    }

    .Slider--vertical > & {
        @include dimensions(5, 98%);
        @include position(absolute, $top: 1%, $right: u(3), $z: 50);
    }

    &-drag {
        @include dimensions(100%);
        @include position(relative, $top: 0, $left: 0);
        background-color: rgba($cc-black, 0.5);
        border-radius: u(10);
    }

    &-cursor-drag {
        cursor: move;
    }

    &.is-locked {
        display: none;
    }
}
