/*
    Site Overlay
 */

.SiteOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: var(--appHeight, 100vh);
    width: 100%;
    z-index: 100;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        z-index: -2;
        width: 100%;
        height: 100vh;
        background-color: rgba($cc-black, 0.6);
    }
}

.SiteOverlay-container {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 64rem;
    height: 100%;
    overflow: hidden;
    color: $white;
    background-color: $cc-grey--700;
    box-shadow: 0 0 20px rgba($cc-black, 0.2);
}

.SiteOverlay-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

// Header

.SiteOverlayHeader {
    padding: 3rem 3rem 1.5rem;
}

.SiteOverlayHeader-actions {
    display: flex;
    align-items: center;
    padding-top: 3rem;

    > *:not(:last-child) {
        margin-right: 2rem;
    }
}

.SiteOverlayHeader-link {
    color: $white;
    opacity: .5;
    display: flex;
    align-items: center;
    @include transition(opacity, .25s);
    .Icon {
        transform: rotate(180deg);
    }
    &:hover,
    &:focus {
        opacity: 1;
    }
}

// Body

.SiteOverlayBody {
    flex: 1 1 0;
    position: relative;
    margin-top: 3rem;
    padding-bottom: 3rem;
    height: 100%;
    overflow-x: hidden;
}

.SiteOverlayBody-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: .4s transform cubic-bezier(.09,.2,.28,.78) .1s;
}

.SiteOverlayBody-menu {
    display: flex;
    flex-direction: column;
    position: relative;

    > * + * {
        margin-top: 2rem;
    }
}

// Menu

.SiteOverlayMenu {
    padding-right: 6rem;
    padding-left: 3rem;
}

.SiteOverlayMenu-title {
    margin-bottom: 2rem;
}

.SiteOverlayMenu-titleLink {
    text-decoration: none;

    &:hover,
    &:active {
        text-decoration: none;
    }
}

.SiteOverlayMenu-list {
    flex-direction: column;
    padding-left: 2rem;
    border-left: 1px solid rgba($white, 0.2);
}

.SiteOverlayMenu-item {
    + .SiteOverlayMenu-item {
        margin-top: 1.5rem;
    }
    &:not(.SiteOverlayMenu-item--alt) {
        .SiteOverlayMenu-link {
            font-size: 1.8rem;
        }
        + .SiteOverlayMenu-item--alt {
            margin-top: 4rem;
        }
    }
    &.SiteOverlayMenu-item--alt {
        .SiteOverlayMenu-link {
            opacity: .8;
        }
    }
}

.SiteOverlayMenu-link {
    cursor: pointer;
    display: inline-flex;
    align-items: baseline;
    color: $white;
    margin-bottom: 0;
    transition: color 150ms, opacity 100ms;

    .Icon {
        top: 1px;
        margin-left: 0.5rem;
        transition: transform 100ms;
    }

    &:hover,
    &:active {
        color: $link-color--hover;
        opacity: 1 !important;
    }
}

.SiteOverlayMenu-lang {
    flex-direction: row;
    margin: 1.5rem -.75rem -.75rem;

    .Nav-item {
        margin: .75rem;
    }
    .Nav-link {
        cursor: pointer;
        display: inline-flex;
        align-items: baseline;
        color: $white;
        margin-bottom: 0;
        opacity: .65;
        &.is-active {
            opacity: 1;
        }
        &:hover,
        &:focus {
            color: $link-color--hover;
            opacity: 1;
        }
    }
}

.SiteOverlayMenu--nested {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 10;
    width: 100%;
}

// Sidebar

.SiteOverlaySidebar {
    position: absolute;
    top: 3rem;
    right: 2rem;
    z-index: 10;
}

// Button

.SiteOverlayButton,
.SiteOverlaySidebar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 5rem;
    height: 5rem;

    &:hover,
    &:focus {
        color: $white;
    }
}

.SiteOverlayButton {
    --btn-border-color: #{$cc-grey--700};
    --btn-text-color: var(--btn-border-color);
    --btn-bg-color: $white;
    --btn-border-color-hover: var(--btn-border-color);
    --btn-text-color-hover: $white;
    --btn-bg-color-hover: var(--btn-border-color-hover);
    --btn-border-color-active: var(--btn-border-color-hover);
    --btn-text-color-active: var(--btn-text-color-hover);
    --btn-bg-color-active: var(--btn-border-color-active);
}

.SiteOverlaySidebar-button {
    margin-left: auto;

    --btn-border-color: $white;
    --btn-text-color: #{$cc-grey--700};
    --btn-bg-color: $white;
    --btn-border-color-hover: #{$cc-green};
    --btn-text-color-hover: $white;
    --btn-bg-color-hover: #{$cc-green};
    --btn-border-color-active: var(--btn-border-color-hover);
    --btn-text-color-active: var(--btn-text-color-hover);
    --btn-bg-color-active: var(--btn-border-color-active);
}

// Transitions

.SiteOverlay--enter {
    transition: visibility 1000ms cubic-bezier(0.165, 0.85, 0.45, 1);
}

.SiteOverlay--leave {
    transition: visibility 500ms cubic-bezier(0.165, 0.85, 0.45, 1);
    transition-delay: 500ms;
}

.SiteOverlay--enter::before {
    transition: opacity 500ms cubic-bezier(1, 0, 0.35, 1);
}

.SiteOverlay--leave::before {
    transition: opacity 500ms cubic-bezier(1, 0, 0.35, 1);
    transition-delay: 500ms;
}

.SiteOverlay--enter-end,
.SiteOverlay--leave-start {
    visibility: visible;

    &::before {
        opacity: 1;
    }
}

.SiteOverlay--enter-start,
.SiteOverlay--leave-end {
    visibility: hidden;

    &::before {
        opacity: 0;
    }
}

.SiteOverlay--enter .SiteOverlay-container {
    transition: transform 500ms cubic-bezier(0.75, 0, 0.45, 0.90);
    transition-delay: 200ms;
}

.SiteOverlay--leave .SiteOverlay-container {
    transition: transform 500ms cubic-bezier(0.75, 0, 0.45, 0.90);
    transition-delay: 200ms;
}

.SiteOverlay--enter-start .SiteOverlay-container,
.SiteOverlay--leave-end .SiteOverlay-container {
    transform: translateX(-100%);
}

.SiteOverlay--enter-end .SiteOverlay-container,
.SiteOverlay--leave-start .SiteOverlay-container {
    transform: translateX(0);
}

.SiteOverlay--enter .SiteOverlay-inner {
    transition: opacity 500ms;
    transition-delay: 500ms;
}

.SiteOverlay--leave .SiteOverlay-inner {
    transition: opacity 500ms;
}

.SiteOverlay--enter-start .SiteOverlay-inner,
.SiteOverlay--leave-end .SiteOverlay-inner {
    opacity: 0;
}

.SiteOverlay--enter-end .SiteOverlay-inner,
.SiteOverlay--leave-start .SiteOverlay-inner {
    opacity: 1;
}
