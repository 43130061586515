/* Object: Button */

.#{$button-namespace}Button {
    -webkit-appearance: none;
    align-items: center;
    background-color: $cc-green;
    border-radius: 0;
    border: none;
    color: $cc-black;
    cursor: pointer;
    display: inline-flex;
    font-family: $button-font-stack;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    justify-content: center;
    letter-spacing: .2rem;
    line-height: $button-line-height;
    padding: $button-padding--t $button-padding--r $button-padding--b $button-padding--l;
    position: relative;
    overflow: hidden;
    min-height: 5rem;
    text-align: $button-text-align;
    text-decoration: none;
    text-transform: uppercase;
    transition: .3s background-color cubic-bezier(.4,.57,.32,.77),
                .25s color cubic-bezier(.4,.57,.32,.77);
    user-select: none;

    /*
        Elements
     */

    .Button-label {
        word-break: break-word;
        /*color: $cc-black;
        transition: .3s color ease .15s;*/
    }

    .Button-icon {
        display: block;
        height: 1.25rem;
        width: 1.25rem;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        path,
        polygon,
        rect {
            fill: currentColor;
            transition: .2s fill ease-out .25s;
        }
    }

    /*
        States
     */

    &:hover,
    &:focus,
    &.is-hover,
    &.is-focus,
    &.is-active {
        color: $white;
        background-color: $black;
        text-decoration: none;
        outline: 0;
        transition: .4s background-color cubic-bezier(.4,.57,.32,.77),
                    .35s color cubic-bezier(.4,.57,.32,.77) .1s;
    }

    &:disabled,
    &.is-disabled {
        box-shadow: none;
        cursor: default;
        opacity: strip-unit($button-disabled-opacity / 100);
        pointer-events: none;
    }

    // Remove excess padding and border in Firefox 4+
    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }

    /*
        Modifiers
     */

    &.Button--mamboJambo {
        background-color: transparent;

        &:hover,
        &:focus,
        &.is-hover,
        &.is-focus,
        &.is-active {
            color: $cc-black;
            background-color: $cc-grey--100;
        }

        // Light background
        .Block--bg-grey-100 & {
            background-color: $cc-grey--300;
            &:hover,
            &:focus,
            &.is-hover,
            &.is-focus,
            &.is-active {
                background-color: $white;
            }
        }
    }
}
