/* Alt Brick */

.AltBrick {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    position: relative;
    background-color: $cc-black;
    color: $cc-grey--100;
    @include mq($viewport--md) {
        grid-template-columns: 2fr 1fr;
    }
    @include mq($viewport--lg) {
        grid-template-columns: 1.25fr 1fr;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}

.AltBrick-body {
    padding: 4rem;
    @include mq($viewport--md) {

    }
    @include mq($viewport--lg) {
        padding: 6.5rem 7.5rem ;
    }
    @include mq($viewport--xl) {

    }
    &:only-child {
        @include mq($viewport--md) {
            grid-column: 1 / 3;
        }
    }
}

.AltBrick-image {
    @include mq($until: $viewport--md - 1) {
        display: none;
    }
    picture {
        height: 100%;
    }
}

.AltBrick-title {
    font-size: 2.2rem;
    color: $cc-grey--100;
}

.AltBrick-text {
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 3rem;
    @include mq($from: $viewport--md) {
        margin-bottom: 4rem;
    }
}

.AltBrick-form {

}

.AltBrick-newsletter {

}

.AltBrick-newsletterNotice {
    opacity: .45;
    font-size: 1.2rem;
    margin: 2rem 0 0;
    padding: 0 1rem;
}

.AltBrick-background {
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
    width: 10rem;
    height: 5.5rem;
    overflow: hidden;
    display: none;
    @include mq($viewport--md) {
        display: block;
    }
    @include mq($viewport--lg) {
        width: 20rem;
        height: 11rem;
    }
    svg {
        display: block;
        height: 100%;
        width: 100%;
    }
    path,
    polygon,
    rect {
        fill: $cc-green;
    }
}
