/* Secondary navigation */
.Nav--headerSecondary {
    display: none;

    @include mq(920) {
        display: block;
    }

    // List
    .Nav-list {
        margin: -.5rem -.75rem;
        @include mq($viewport--lg) {
            margin: -.5rem -1.25rem;
        }
        @include mq($viewport--xl) {
            margin: -.5rem -1.75rem;
        }
    }

    // Item
    .Nav-item {
        margin: .5rem .75rem;
        @include mq($viewport--lg) {
            margin: .5rem 1.25rem;
        }
        @include mq($viewport--xl) {
            margin: .5rem 1.75rem;
        }
    }

    // Link
    .Nav-link {
        font-family: $font-saira;
        font-size: 1.3rem;
        font-weight: 400;
        color: $cc-black;

        span {
            opacity: .65;
        }

        &:focus,
        &:hover {
            span {
                opacity: 1;
            }
        }

        // - active
        // Active state
        &.is-active {
            &:before {
                content: "";
                position: absolute;
                bottom: -.5rem;
                left: 0;
                height: .3rem;
                width: 1.5rem;
                background-color: $cc-green;
            }
        }
    }
}
