/* Slider: lazy */
.Slider-preloader {
    @include dimensions(42);
    @include position(absolute, $top: 50%, $left: 50%, $z: 10);
    animation: slider-preloader-spin 1s steps(12, end) infinite;
    box-sizing: border-box;
    border: px(4) solid var(--slider-preloader-color, var(--slider-theme-color));
    border-radius: 50%;
    border-top-color: transparent;
    margin-top: u(-21px);
    margin-left: u(-21px);
    transform-origin: 50%;

    &--white {
        --slider-preloader-color: #{$white};
    }

    &--black {
        --slider-preloader-color: #{$cc-black};
    }
}

@keyframes slider-preloader-spin {

    100% {
        transform: rotate(360deg);
    }
}
