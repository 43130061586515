/* Brick */

.Brick {
    display: block;
    position: relative;
    height: 100%;
    background-color: $cc-grey--700;
    color: $cc-green;

    &-body {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 4rem;
        height: 100%;
        @include mq($viewport--md) {
            width: 75%;
        }
        @include mq($viewport--lg) {
            padding: 6rem;
        }
        @include mq($viewport--xl) {
            padding: 8rem 7rem;
            width: 100%;
            max-width: 48rem;
        }
    }

    &-tagline {
        display: block;
        margin-bottom: .75rem;
        opacity: .75;
        color: $cc-grey--100;
    }

    &-title {
        color: $cc-green;
    }

    &-button,
    &-link {
        margin-top: auto;
    }

    &-link {
        cursor: pointer !important;
    }

    &-background {
        position: absolute;
        bottom: 0;
        right: 0;
        pointer-events: none;
        width: 10rem;
        height: 5.5rem;
        overflow: hidden;
        display: none;
        @include mq($viewport--sm) {
            display: block;
        }
        @include mq($viewport--lg) {
            width: 20rem;
            height: 11rem;
        }
        svg {
            display: block;
            height: 100%;
            width: 100%;
        }
        path,
        polygon,
        rect {
            fill: $cc-green;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }

    &:not(.Brick--slim) {
        .Brick-title {
            //margin-bottom: 5rem;
            @include mq($viewport--xl) {
                font-size: u($heading-size-2);
            }
        }
    }

    &--slim {
        .Brick-body {
            padding: 4rem;
            width: 100%;
            max-width: none;
        }
        .Brick-background {
            width: 12.6rem;
            height: 10.8rem;
        }
    }

    &--alt {
        background-color: $cc-green;
        color: $cc-grey--700;

        .Brick-tagline,
        .Brick-title {
            color: $cc-grey--700;
        }
        .Brick-body {
            @include mq($viewport--xl) {
                max-width: 68rem;
            }
        }
        .Brick-background {
            path,
            polygon,
            rect {
                fill: $cc-black;
            }
        }
    }
}

a.Brick {
    text-decoration: none;
    &:hover,
    &:focus {
        background-color: $cc-black;
    }
}
