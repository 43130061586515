/*
    Footer
 */

.Footer {
    padding: 5rem 0 2.5rem;
    background-color: $cc-black;
    color: $white;

    @include mq($from: $viewport--md) {
        padding: 6rem 0 3rem;
    }

    &.hasPixels {
        padding-top: 9rem;
        @include mq($from: $viewport--lg) {
            padding-top: 16rem;
        }
        @include mq($from: $viewport--xl) {
            padding-top: 20rem;
        }
    }

    &-top,
    &-center,
    &-bottom {
        position: relative;
        z-index: 1;
    }

    // Top
    &-top {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-gap: 3rem;
        @include mq($from: $viewport--sm) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include mq($from: $viewport--md) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @include mq($from: $viewport--lg) {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }
        @include mq($from: $viewport--xl) {
            grid-gap: 6rem;
        }
        @include mq(1400) {
            grid-gap: 8rem;
        }

        .Footer-title {
            margin-bottom: 1.5rem;
            @include mq($from: $viewport--md) {
                margin-bottom: 3rem;
            }
        }

        + .Footer-center {
            margin-top: 4rem;
            @include mq($from: $viewport--md) {
                margin-top: 6rem;
            }
            @include mq($from: $viewport--xl) {
                margin-top: 8rem;
            }
            @include mq(1400) {
                margin-top: 13rem;
            }
        }
    }

    // Center
    &-center {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-gap: 4rem;
        @include mq($from: $viewport--lg) {
            grid-gap: 6rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include mq($from: $viewport--xl) {
            grid-template-columns: repeat(5, minmax(0, 1fr));

        }
        @include mq(1400) {
            grid-gap: 8rem;
        }

        .Footer-item {
            &:first-child {
                @include mq($from: $viewport--xl) {
                    grid-column: 1 / span 2;
                }
            }
            &:last-child {
                @include mq($from: $viewport--xl) {
                    grid-column: 3 / span 3;
                }
            }
        }

        + .Footer-bottom {
            margin-top: 3rem;
            @include mq($from: $viewport--sm) {
                margin-top: 4rem;
            }
            @include mq($from: $viewport--xl) {
                margin-top: 6rem;
            }
        }
    }

    // Bottom
    &-bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include mq($from: $viewport--xl) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    // - Items
    &-item {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    // - Sub items
    &-subItems {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        @include mq($from: $viewport--md) {
            gap: 4rem;
        }
        @include mq($from: $viewport--xl) {
            flex-direction: row;
        }
    }
    &-subItem {
        > *:last-child {
            margin-bottom: 0;
        }
        &:first-child {
            @include mq($from: $viewport--xl) {
                flex-basis: 60rem;
            }
        }
        &:last-child {
            @include mq($from: $viewport--xl) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    // - Inner elements
    &-title {
        font-size: 2.2rem;
        color: $cc-grey--100;
    }
    &-manifest {
        margin-bottom: 2rem;
        @include mq($from: $viewport--md) {
            margin-bottom: 2.5rem;
        }
        @include mq($from: $viewport--xl) {
            margin-bottom: 6rem;
        }
        p {
            font-family: $font-saira;
            @include font-size($heading-size-3, $heading-spacing-3);
            font-weight: 300;
            line-height: 1.2;
            margin-bottom: 0;
            @include mq($from: $viewport--sm) {
                width: 75%;
            }
            @include mq($from: $viewport--md) {
                font-size: 4.5rem;
            }
            @include mq($from: $viewport--lg) {
                width: 100%;
            }
        }
        strong {
            color: $cc-green;
        }
    }
    &-data {
        font-size: 1.4rem;
        list-style-type: none;
        padding-left: 0;
        li {
            display: flex;
            flex-direction: column;
            @include mq($from: 720, $until: $viewport--lg - 1) {
                flex-direction: row;
                align-items: center;
                gap: 2rem;
            }
        }
        span {
            &:first-child {
                flex: 0 0 auto;
                min-width: 2rem;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
        address {
            font-style: normal;
        }
        a {
            text-decoration: none;
            color: $white;
            opacity: .7;
            &:hover, &:focus {
                opacity: 1;
            }
        }

        &--alt {
            span {
                &:first-child {
                    min-width: 7.5rem;
                }
            }
        }
    }

    // Newsletter
    &-newsletter {
        &Form {

        }
        &Notice {
            opacity: .45;
            font-size: 1.2rem;
            margin: 2rem 0 0;
            padding: 0 1rem;
        }
        + .Nav--socialMedia {
            margin-top: auto;
            padding-top: 2rem;
            @include mq($from: $viewport--sm) {
                padding-top: 3rem;
            }
        }
    }

    // Pixels
    .Pixels {
        background: $cc-grey--100;
        bottom: auto;
        top: 0;

        svg {
            margin-bottom: -1px;
        }
    }
}

.Footer-badge {
    flex-shrink: 0;
    margin-bottom: 2rem;
    @include mq($from: $viewport--sm) {
        margin-bottom: 3rem;
    }
    @include mq($from: $viewport--xl) {
        margin-bottom: 0;
    }
    .carbonbadge {
        --b1: #{$cc-grey--700} !important;
        --b2: #{$cc-green} !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: .25rem;
        color: rgba($white, .45) !important;
        @include mq($from: $viewport--md) {
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
        #wcb_2, #wcb_a, #wcb_g {
            font-family: $font-open-sans !important;
            font-size: 1.4rem !important;
            font-weight: 400 !important;
            line-height: 1.5 !important;
        }
        #wcb_a, #wcb_g {
            padding: .15em .75em 0.35rem !important;
        }
        #wcb_2, #wcb_g {
            font-weight: 400 !important;
        }
        #wcb_a {
            font-weight: 600 !important;
        }
        #wcb_g {
            background: none !important;
        }
        #wcb_2 {
            opacity: .45;
        }
    }
}

.Main--lastBlockHandle-typeBox + .Footer .Pixels,
.Main--lastBlockHandle-typeLocations + .Footer .Pixels,
.Main--lastBlockColor-white + .Footer .Pixels {
    background: $white;
}
.Main--lastBlockColor-grey-100 + .Footer .Pixels {
    background: $cc-grey--100;
}
.Main--lastBlockColor-grey-700 + .Footer .Pixels {
    background: $cc-grey--700;
}
.Main--lastBlockColor-black + .Footer .Pixels {
    background: $cc-black;
}

.ErrorPage {
    .Footer.hasPixels {
        padding-top: 5rem;
        @include mq($from: $viewport--md) {
            padding-top: 6rem
        }
        .Pixels {
            display: none;
        }
    }
}
