/* Box */

.Box {
    background-color: $cc-grey--700;
    color: $white;
    position: relative;
    z-index: 1;
    padding: 5rem 0;

    @include mq($from: $viewport--lg) {
        padding: 10rem 0 5rem;
    }
    @include mq($from: $viewport--xl) {
        padding: 13rem 0 9rem;
    }

    // Wrapper
    &-wrapper {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-gap: 5rem;

        @include mq($viewport--lg) {
            grid-template-columns: 1fr minmax(auto, 54rem);
            grid-gap: 10rem;
        }
    }

    // Body
    &-body {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    /* - tagline */
    &-tagline {
        opacity: .5;
    }

    /* - title */
    &-title {
        color: $cc-green;
        @include mq($viewport--lg) {
            font-size: 5.5rem;
            margin-bottom: 4rem;
        }
    }

    /* - text */
    &-text {
        margin-bottom: 2rem;
        @include mq($viewport--lg) {
            margin-bottom: 4rem;
        }
    }

    // Media
    &-media {
        position: relative;
        height: 40vw;
        width: calc(100% + 4rem);
        margin: 0 -2rem;
        @include mq($viewport--md) {
            width: 100%;
            margin: 0;
        }
        @include mq($viewport--lg) {
            flex: 1 1 50%;
            top: -1rem;
            height: auto;
        }
        @include mq($viewport--xl) {
            top: -2rem;
        }
        &Inner {
            height: 100%;
            @include mq($from: $viewport--lg) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% + 15rem);
            }
            @include mq($viewport--xl) {
                height: calc(100% + 20rem);
            }
        }
        picture {
            height: 100%;
            &:before {
                content: none;
            }
        }
    }

    // - pixels
    &-pixels {
        display: none;
        position: absolute;
        top: 0;
        left: -2px;
        z-index: 1;
        width: calc(100% + 4px);
        height: 100%;
        align-items: flex-end;
        pointer-events: none;
        overflow: hidden;
        @include mq($viewport--lg) {
            display: flex;
        }
        svg {
            display: block;
            width: 100%;
            position: relative;
            top: 1px;
        }
        path,
        polygon,
        rect {
            fill: $white;
        }
        &.Box-pixels--bg-white {
            path,
            polygon,
            rect {
                fill: $white;
            }
        }
        &.Box-pixels--bg-grey-100 {
            path,
            polygon,
            rect {
                fill: $cc-grey--100;
            }
        }
        &.Box-pixels--bg-grey-700 {
            path,
            polygon,
            rect {
                fill: $cc-grey--700;
            }
        }
        &.Box-pixels--bg-black {
            path,
            polygon,
            rect {
                fill: $cc-black;
            }
        }
    }

    &.has-media .Box-media {
        @include mq($until: $viewport--md - 1) {
            margin-bottom: -5rem;
        }
    }

    &[data-box-flex-direction="row-reverse"] {
        @include mq($viewport--lg) {
            .Box-wrapper {
                grid-auto-flow: dense;
            }
            .Box-body {
                grid-column: 2;
            }
            .Box-media {
                grid-column: 1;
            }
        }
    }
}
