/* Long Form */

.LongForm {

    .Block {
        + .Block {
            margin-top: 2.5rem;
            @include mq($from: $viewport--md) {
                margin-top: 4rem;
            }
            @include mq($from: $viewport--lg) {
                margin-top: 8rem;
            }
        }
        &.Block--noPadding {
            padding: 0;
        }
        &.Block--bg-white {
            .BlockGrid-body {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .Features {
        padding: 2.5rem;
    }
}
