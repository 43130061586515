/* Bolt */

.Bolt {
    width: 100%;
    word-break: break-word;
    background-color: $white;
    padding: 1.5rem 1.5rem 2rem;
    margin-bottom: 2rem;
    @include mq($viewport--sm) {
        margin-bottom: 1rem;
        max-width: 24rem;
    }
    @include mq($viewport--lg) {
        margin-bottom: 1.75rem;
        max-width: 32rem;
    }

    // Block position
    &[data-bolt-position="left"] {
        @include mq($viewport--sm) {
            float: left;
            margin-right: 2rem;
        }
        @include mq($viewport--lg) {
            margin-right: 3.5rem;
            margin-left: -4rem;
        }
        @include mq($viewport--xl) {
            margin-left: -12rem;
        }

        // ...
        ~ ul,
        ~ ol {
            @include mq($viewport--sm) {
                position: relative;
                left: 2rem;
                width: calc(100% - 2rem);
            }
        }
    }
    &[data-bolt-position="right"] {
        @include mq($viewport--sm) {
            float: right;
            margin-left: 2rem;
        }
        @include mq($viewport--lg) {
            margin-left: 3.5rem;
            margin-right: -4rem;
        }
        @include mq($viewport--xl) {
            margin-right: -12rem;
        }
    }

    // Block types
    &[data-bolt-type="card"] {

    }
    &[data-bolt-type="cta"] {
        padding: 0;
        .Brick {
            &-body {
                padding: 2rem;
            }
            &-title {
                font-size: 2.6rem;
            }
            &-background {
                display: none;
            }
        }
    }
    &[data-bolt-type="quote"] {

    }
}

// Light background
.Block--bg-white {
    .Bolt {
        background-color: $cc-grey--100;
    }
}
