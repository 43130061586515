/*
    Features
 */

.Features {

    &.has-pixels {
        margin-bottom: 5rem;
        @include mq($from: $viewport--lg) {
            margin-bottom: 7.5rem;
        }
    }

    + .Pixels {
        bottom: -5rem;
        @include mq($from: $viewport--lg) {
            bottom: -10rem;
        }
    }
}

.Features-items {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-gap: 2.5rem;
    align-items: start;
    @include mq($from: $viewport--sm) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include mq($from: $viewport--md) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.Features-item {
    display: block;
    font-family: $font-saira;
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1;
    color: inherit;
    @include mq($from: $viewport--md) {

    }
}

.Features-itemNumber {
    display: block;
    font-size: 5.5rem;
    color: $cc-green;
    margin-bottom: 1.5rem;
}

.Features-itemLabel {
    display: block;
    font-size: 2.8rem;
}

.Block.Block--bg-grey-700,
.Block.Block--bg-black {
   .Features {
       color: $white;
   }
}

.Features-items.Features-items--alt {
    @include mq($from: $viewport--md) {
        grid-template-columns: repeat(3, 1fr);
    }
}
