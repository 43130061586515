/* Colors: Custom */

// Accent
$cc-green:                      #68EEA0;
$cc-green--500:                 darken($cc-green, 10%);
$cc-green--600:                 darken($cc-green, 15%);

// Greys
$cc-grey--100:                  #F3EFE7;
$cc-grey--300:                  #F6F6F6;
$cc-grey--400:                  false;
$cc-grey--500:                  false;
$cc-grey--600:                  #858585;
$cc-grey--700:                  #303133;
$cc-black:                      #131416;

// Box shadow
$box-shadow: 2px 2px 18px 1px rgba($cc-black, 0.05);
