/* Block Tiles */

.BlockTiles {
    display: flex;
    flex-direction: column;

    &-title {
        margin-bottom: 4rem;
        @include mq($viewport--lg) {
            font-size: 5.5rem;
            margin-bottom: 5rem;
        }
    }

    &-items {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-gap: 2.5rem;
        align-items: start;
        margin-bottom: 3rem;
        @include mq($from: $viewport--sm) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($from: $viewport--md) {
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: 5rem;
        }

        &--smallGapSize {
            grid-gap: 2rem;
            margin-bottom: 0;
        }
        &--mediumGapSize {
            grid-gap: 2.5rem;
            margin-bottom: 0;
        }
        &--largeGapSize {
            grid-gap: 5rem;
            margin-bottom: 0;
        }
        &--extraLargeGapSize {
            grid-gap: 10rem;
            margin-bottom: 0;
        }
        @include block-tiles(2, 1, 1);
        @include block-tiles(3, 2, 1);
        @include block-tiles(4, 2, 1);

    }

    &-item {
        position: relative;
        display: flex;
        padding: 0;
        margin-bottom: 0;
        > * {
            align-self: center;
        }
    }

    &-button {
        align-self: center;
    }

    > *:last-child {
        margin-bottom: 0;
    }
    &.BlockTiles--column {
        .BlockTiles-items {
            align-items: stretch;
        }
        .BlockTiles-item {
            background-color: $white;
            .Card {
                height: 100%;
                .Card-images {
                    .Card-placeholder {
                        margin-bottom: 0;
                        background-color: transparent;
                        overflow: auto;
                    }
                    .Card-pixels {
                        transform: translateY(-3px);
                        bottom: 0;
                        svg {
                            height: 100%;
                        }
                    }
                }
            }
        }
        
    }
}

.Block.Block--bg-grey-700,
.Block.Block--bg-black {
    .BlockTiles-title {
        color: $cc-green;
    }
}
