/* Block List */

.BlockList {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: start;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
    @include mq($from: $viewport--sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3rem 4rem;
    }
    @include mq($from: $viewport--lg) {
        grid-gap: 6rem 8rem;
        grid-template-columns: repeat(4, 1fr);
    }
    @include mq($from: 1400) {
        grid-gap: 8rem 12rem;
    }

    &-item {
        position: relative;
        margin-bottom: 0;
        height: 100%;

        > * {
            height: 100%;
        }
    }
}
