/* Navigation */
.Navigation {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    width: 100%;
    @include mq(920) {
        grid-gap: 2rem;
    }
    @include mq($viewport--lg) {
        grid-gap: 3rem;
    }
    @include mq(1400) {
        grid-gap: 4rem;
    }
    &-item {
        display: flex;
        align-items: center;
        &:first-child {
            grid-row: 2;
            flex-direction: column-reverse;
            align-items: flex-end;
            margin: -.5rem -1rem;
            > * {
                margin: .5rem 1rem;
            }
            @include mq(1280) {
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                margin: -1rem -2rem;
                > * {
                    margin: 1rem 2rem;
                }
            }
        }
        &:last-child {
            grid-row: 1;
            justify-content: flex-end;
            margin: -.5rem -.75rem;
            > * {
                margin: .5rem .75rem;
            }
            @include mq($viewport--lg) {
                margin: -.5rem -1.25rem;
                > * {
                    margin: .5rem 1.25rem;
                }
            }
            @include mq($viewport--xl) {
                margin: -.5rem -1.75rem;
                > * {
                    margin: .5rem 1.75rem;
                }
            }
        }
    }
}

/* Types */
@import '6-components/navigation/footer-primary';
@import '6-components/navigation/footer-secondary';
@import '6-components/navigation/header-primary';
@import '6-components/navigation/header-secondary';
@import '6-components/navigation/lang';
@import '6-components/navigation/pagination';
@import '6-components/navigation/social-media';
