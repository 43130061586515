/* Uni */

.Uni {

    > *:last-child {
        margin-bottom: 0;
    }

    /* - tagline */
    &-tagline {
        display: block;
        margin-bottom: .5rem;
        opacity: .55;
    }

    /* - title */
    &-title {
        @include mq($viewport--lg) {
            font-size: 5.5rem;
            margin-bottom: 4rem;
        }
    }

    /* - text */
    &-text {
        margin-bottom: 2rem;
        @include mq($viewport--lg) {
            margin-bottom: 4rem;
        }

        &--wrapped {
            &:after {
                content: "";
                clear: both;
                display: table;
            }
        }
    }

    /* Other elements */
    + .BlockList {
        margin-top: 5rem;
        @include mq($viewport--lg) {

        }
    }
}
