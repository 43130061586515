/* Block Grid */

.BlockGrid {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-gap: 5rem;
    position: relative;
    z-index: 2;
    @include mq($viewport--lg) {
        grid-template-columns: 1fr minmax(auto, 54rem);
        grid-gap: 10rem;
        align-items: center;
    }

    /* - cell */
    &-cell {
        position: relative;
        @include mq($viewport--lg) {

        }
        &:only-child {
            @include mq($viewport--lg) {
                grid-column: 1 / 3;
            }
        }
    }

    /* -- body */
    &-body {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    /* -- image */
    &-image {
        picture,
        img {
            width: 100%;
        }
    }

    /* -- video */
    &-video {
        .plyr {
            width: 100%;
            &.plyr--stopped .plyr__controls {
                display: none
            }
        }

        &.has-poster {
            .plyr__poster {
                background-size: cover;
            }
        }
    }

    /*&-image,
    &-video {
        @include mq($until: $viewport--md - 1) {
            margin-bottom: -5rem;
        }
    }*/

    /* --- tagline */
    &-tagline {
        display: block;
        margin-bottom: .5rem;
        opacity: .5;
    }

    /* --- title */
    &-title {
        @include mq($viewport--lg) {
            font-size: 5.5rem;
            margin-bottom: 4rem;
        }
    }

    /* --- text */
    &-text {
        margin-bottom: 2rem;
        @include mq($viewport--lg) {
            margin-bottom: 4rem;
        }

        &--wrapped {
            &:after {
                content: "";
                clear: both;
                display: table;
            }
        }
    }

    /* --- duo */
    &-duo {
        display: flex;
        flex-direction: column;
        margin: -4rem -4rem 0;
        @include mq($viewport--xl) {
            flex-direction: row;
        }
    }
    &-duoItem {
        position: relative;
        display: flex;
        padding: .25rem;
        margin: 4rem 4rem 0;
        &:first-child {
            flex-shrink: 0;
            max-width: 20rem;
            &:before {
                content: "";
                position: absolute;
                bottom: -2.1rem;
                left: 0;
                width: 5rem;
                height: .1rem;
                background-color: $cc-grey--700;
                opacity: .25;
                @include mq($viewport--xl) {
                    top: 0;
                    left: auto;
                    right: -4.1rem;
                    width: .1rem;
                    height: 100%;
                }
            }
        }
        > * {
            align-self: center;
        }
    }

    /* --- list */
    &-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    &-listItem {

    }

    /* --- pixels */
    &-pixels {
        position: absolute;
        bottom: 0;
        right: 0;
        pointer-events: none;
        svg {
            display: block;
            height: 9rem;
            width: 10.8rem;
        }
        path,
        polygon,
        rect {
            fill: $white;
        }
        &.BlockGrid-pixels--bg-white {
            path,
            polygon,
            rect {
                fill: $white;
            }
        }
        &.BlockGrid-pixels--bg-grey-100 {
            path,
            polygon,
            rect {
                fill: $cc-grey--100;
            }
        }
        &.BlockGrid-pixels--bg-grey-700 {
            path,
            polygon,
            rect {
                fill: $cc-grey--700;
            }
        }
        &.BlockGrid-pixels--bg-black {
            path,
            polygon,
            rect {
                fill: $cc-black;
            }
        }
    }

    /* Modifiers */
    &--largeImage {
        @include mq($viewport--lg) {
            align-items: stretch;
        }
        .BlockGrid-body {
            @include mq($viewport--md) {
                padding-top: 10rem;
            }
            @include mq($viewport--lg) {
                padding-top: 8rem;
                padding-bottom: 8rem;
            }
        }
        .BlockGrid-image,
        .BlockGrid-video {
            position: relative;
            left: -2rem;
            width: calc(100% + 4rem);
            @include mq($viewport--md) {
                left: -4rem;
                width: calc(100% + 8rem);
            }
            @include mq($viewport--lg) {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: calc(100% + 4rem);
                display: flex;
                picture {
                    height: 100%;

                    &:before {
                        content: none;
                    }
                }
                img {
                    position: static;
                }
            }
            @include mq(1400) {
                // Calcalation:
                // ---
                // * 100%: width of element
                // * 100vw: width of viewport
                // * 142rem: container--lg width
                // * 8rem: total padding of container (4 + 4)
                // * 2: only one "side"
                // ---
                // 100 + (viewport - container width + padding) = "outer" or "margin" width ...
                // /2
                width: calc(100% + ((100vw - 142rem + 8rem) / 2));
            }
            @include mq($from: 2000) {
                max-width: 78rem;
            }
        }
    }
    &--alt {
        @include mq($viewport--lg) {
            grid-gap: 8rem;
        }
        .BlockGrid-body {
            padding: 2.5rem 0 2.5rem 2.5rem;
            @include mq($viewport--lg) {
                padding: 6.5rem 0 6.5rem 7.5rem ;
            }
        }
    }

    /* Attributes */
    &[data-block-grid-flex-direction="row-reverse"] {
        @include mq($viewport--lg) {
            grid-auto-flow: dense;
            .BlockGrid-cell {
                &:nth-child(1) {
                    grid-column: 2;
                }
                &:nth-child(2) {
                    grid-column: 1;
                }
            }
        }
    }
    &--largeImage {
        &[data-block-grid-flex-direction="row-reverse"] {
            .BlockGrid-image,
            .BlockGrid-video {
                @include mq($viewport--lg) {
                    left: auto;
                    right: 0;
                }
                @include mq($from: 2000) {
                    max-width: 94rem;
                }
            }
        }
    }
    &--alt {
        &[data-block-grid-flex-direction="row-reverse"] {
            .BlockGrid-body {
                @include mq($viewport--lg) {
                    padding-right: 7.5rem;
                }
            }
        }
    }
}
