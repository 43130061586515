/* Mixin: Font-size */
@mixin font-size($value, $margin-bottom: false) {
    @if not is-number($value) and not is-measure($value) {
        @warn throw('`#{$value}` is not a value for `font-size()`');
    }

    // Calculate `line-height`
    $_line-height: null;

    @if ($line-height / $value) <= 1 {
        @if ($line-height / $value) < 0.5 {
            $_line-height: 1.1;
        } @else {
            $_line-height: 1.2;
        }
    }

    // Output properties and values
    font-size: u($value);
    line-height: $_line-height;
}
