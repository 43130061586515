/*
    Breadcrumbs
 */

.Breadcrumbs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem .75rem;
    color: $cc-grey--700;

    /*+ .PageIntro,
    + .ProductHeader {
        margin-top: 1.5rem;

        @include mq($from: $viewport--md) {
            margin-top: 3rem;
        }
    }*/
}

.Breadcrumbs-item {
    display: block;
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.25;
    color: inherit;
    text-align: left;
    text-decoration: none;
    word-break: break-word;

    .Breadcrumbs-icon {
        height: 1rem;
        width: .9rem;
    }
}

span.Breadcrumbs-item {
    cursor: default;
}

a.Breadcrumbs-item {
    &:hover, &:focus {
        color: $cc-green;
    }
}

.Breadcrumbs-icon {
    display: block;
    height: .8rem;
    width: .5rem;
    opacity: .55;

    polygon,
    path {
        fill: currentColor;
        transition: .3s fill ease;
    }
}

