/* Footer title navigation */
.Nav--footerPrimary {

    // List
    .Nav-list {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 1rem;
        @include mq($viewport--xl) {
            gap: 1.5rem;
        }
    }

    // Item
    .Nav-item {

    }

    // Link
    .Nav-link {
        color: $white;
        opacity: .7;
        &:hover, &:focus {
            opacity: 1;
        }
    }
}
