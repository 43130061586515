/* Primary navigation */
.Nav--headerPrimary {
    display: none;
    @include mq(920) {
        display: block;
    }

    // List
    .Nav-list {
        flex-wrap: nowrap;
        margin: -.5rem -1rem;
        @include mq($viewport--xl) {
            margin: -.5rem -1.5rem;
        }
        @include mq(1400) {
            margin: -.5rem -2rem;
        }
    }

    // Item
    .Nav-item {
        margin: .5rem 1rem;
        @include mq($viewport--xl) {
            margin: .5rem 1.5rem;
        }
        @include mq(1400) {
            margin: .5rem 2rem;
        }
        // Show hover state when using dropdown ...
        &:hover {
            .Nav-link.has-dropdown {
                color: $cc-green;
                .Nav-icon {
                    transform: rotate(180deg);
                    path {
                        fill: $cc-green;
                    }
                }
            }
        }
    }

    // Link
    .Nav-link {
        display: flex;
        align-items: center;
        gap: .6rem;
        font-family: $font-saira;
        font-size: 1.6rem;
        font-weight: 400;
        color: $cc-black;
        @include mq(1400) {
            font-size: 1.8rem;
        }
        &:hover, &:focus {
            + .Dropdown {
                transform: translateY(0);
                pointer-events: all;
                opacity: 1;
                transition: .3s transform ease-out .2s, 0s pointer-events linear .5s, .15s opacity ease-out .2s;
            }
            ~ .Backdrop {
                display: block;
                opacity: 1;
            }
        }
    }

    // Icon
    .Nav-icon {
        display: block;
        height: .8rem;
        width: .8rem;
        transition: .25s transform ease;
        path {
            fill: currentColor;
            transition: .3s fill ease;
        }
    }

    // States

    // - hover
    .Nav-link {
        &:focus,
        &:hover {
            color: $cc-green;
        }
    }
    .Nav-item:hover .Nav-link.has-dropdown,
    .Nav-link.has-dropdown:focus,
    .Nav-link.has-dropdown:hover {
        .Nav-icon {
            transform: rotate(180deg);
            path {
                fill: $cc-green;
            }
        }
        ~ .Backdrop {
            display: block;
            opacity: 1;
        }
    }

    // - active
    // Active state
    .Nav-link.is-active {
        &:before {
            content: "";
            position: absolute;
            bottom: -.5rem;
            left: 0;
            height: .3rem;
            width: 3rem;
            background-color: $cc-green;
        }
        &:focus,
        &:hover {
            color: $cc-green;
        }
    }

    // Use different hover on beige background
    .Header:not(.Header--alt) & {
        .Nav-item {
            &:hover {
                .Nav-link.has-dropdown {
                    @include mq(920) {
                        color: $cc-black;
                    }
                    .Nav-icon {
                        path {
                            @include mq(920) {
                                fill: $cc-black;
                            }
                        }
                    }
                }
            }
        }
        .Nav-item:hover .Nav-link.has-dropdown,
        .Nav-link.has-dropdown:focus,
        .Nav-link.has-dropdown:hover {
            .Nav-icon {
                path {
                    @include mq(920) {
                        fill: $cc-black;
                    }
                }
            }
        }
        .Nav-link.is-active {
            &:focus,
            &:hover {
                @include mq(920) {
                    color: $cc-black;
                }
            }
        }
    }
}
