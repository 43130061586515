/* Location */

.Location {
    display: flex;
    flex-direction: column;

    &-body {
        margin-bottom: 4rem;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            text-decoration: none;
            color: currentColor;
            &:hover, &:focus {
               color: $cc-black;
            }
        }
    }

    &-title {
        margin-bottom: .5rem;
    }
    &-address,
    &-emailAddress,
    &-phoneNumber {
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: .05rem;
    }
    &-address {
        font-style: normal;
    }
    &-emailAddress,
    &-phoneNumber {
        display: block;
    }

    &-image,
    &-placeholder {
        position: relative;
        margin-top: auto;
        background-color: $cc-grey--300;
    }

    &-image {
        img {
            transition: .2s transform cubic-bezier(.4,.59,.54,.97) .125s;
        }
    }

    &-placeholder {

        // Ratios
        @each $ratio-name, $ratio-value in $RATIOS {
            @each $antecedent, $consequent in $ratio-value {
                @if (type-of($antecedent) != number) {
                    @error "`#{$antecedent}` needs to be a number.";
                }
                @if (type-of($consequent) != number) {
                    @error "`#{$consequent}` needs to be a number.";
                }
                &[data-ratio="#{$antecedent}:#{$consequent}"] {
                    padding-bottom: ($consequent/$antecedent) * 100%;
                }
            }
        }
    }
}
