/* Hero */

.Hero {
    position: relative;
    z-index: 3;
    padding: 4rem 0;
    background-color: $cc-black;
    color: $white;

    @include mq($from: $viewport--lg) {
        padding: 5rem 0 10rem;
    }
    @include mq($from: $viewport--xl) {
        padding: 8rem 0 13rem;
    }

    &.has-media {
        @include mq($until: $viewport--lg - 1) {
            padding-bottom: 0;
        }

        // Wrapper
        .Hero-wrapper {
            @include mq($viewport--lg) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 6rem;
            }
            @include mq($viewport--xl) {
                grid-template-columns: 1fr minmax(auto, 54rem);
                grid-gap: 12rem;
            }
        }
    }

    // Wrapper
    &-wrapper {
        position: relative;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-gap: 4rem;
    }

    // Hornet
    &-hornet {
        position: relative;
        .Breadcrumbs {
            position: relative;
            top: -2.5rem;
            color: $cc-grey--100;

            @include mq($from: $viewport--md) {
                position: absolute;
                top: -4rem;
                left: 0;
            }

            .Breadcrumbs-item {
                opacity: .55;
                &:hover,
                &:focus {
                    opacity: .8;
                }
            }
            > .Breadcrumbs-icon {
                opacity: .4;
            }
        }
    }

    // Body
    &-body {
        max-width: 68rem;
        > *:last-child {
            margin-bottom: 0;
        }
    }

    // - title
    &-title {
        position: relative;
        z-index: 2;
        color: $cc-grey--100;
        font-weight: 500;
        @include mq($from: $viewport--xl) {
            margin-bottom: 4rem;
        }
        strong {
            font-weight: normal;
            span {
                display: inline-block;
                color: $white;
                font-weight: 500;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0.5rem;
                    left: -0.75rem;
                    height: calc(100% - 1rem);
                    width: calc(100% + 1.5rem);
                    background-color: $cc-green;
                }
            }
        }
    }

    // - text
    &-text {
        margin-bottom: 2.5rem;
        @include mq($viewport--lg) {
            margin-bottom: 5rem;
        }
        > *:last-child {
            margin-bottom: 0;
        }
        p, ul, ol {
            margin-bottom: 1.5rem;
        }
        strong {
            color: $cc-green;
        }
    }

    // - button
    &-button {
        margin-bottom: 3rem;
        @include mq($viewport--lg) {
            margin-bottom: 5rem;
        }
    }

    // - list
    &-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        display: grid;
        gap: 1.5rem 2rem;
        @include mq($from: $viewport--md) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

    }
    &-listItem {
        margin-bottom: 0;
    }
    &-listLabel {
        display: block;
        font-family: $font-saira;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1;
        color: $cc-grey--100;
        text-transform: uppercase;
        letter-spacing: .2rem;
        border-left: 1px solid $cc-green;
        padding-left: 1rem;
        opacity: .8;
    }

    // Media
    &-media {
        position: relative;
        width: calc(100% + 4rem);
        margin: 0 -2rem;
        @include mq($viewport--md) {
            width: calc(100% + 8rem);
            margin: 0 -4rem;
        }
        @include mq($viewport--lg) {
            width: 100%;
            margin: 0;
            top: -1rem;
        }
        @include mq($viewport--xl) {
            top: -2rem;
        }
        &Inner {
            background-color: $white;

            @include mq($from: $viewport--lg) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% + 13rem);
            }

            @include mq($viewport--lg) {
                height: calc(100% + 15rem);
            }
            @include mq($viewport--xl) {
                height: calc(100% + 20rem);
            }
        }
        picture {
            max-height: 55vw;
            @include mq($viewport--lg) {
                height: 100%;
                max-height: none;
            }
            &:before {
                @include mq($viewport--lg) {
                    content: none;
                }
            }
        }
    }

    // - pixels
    &-pixels {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        pointer-events: none;
        svg {
            display: block;
            width: 100%;
        }
        path,
        polygon,
        rect {
            fill: $white;
        }
        &.Hero-pixels--bg-white {
            path,
            polygon,
            rect {
                fill: $white;
            }
        }
        &.Hero-pixels--bg-grey-100 {
            path,
            polygon,
            rect {
                fill: $cc-grey--100;
            }
        }
        &.Hero-pixels--bg-grey-700 {
            path,
            polygon,
            rect {
                fill: $cc-grey--700;
            }
        }
        &.Hero-pixels--bg-black {
            path,
            polygon,
            rect {
                fill: $cc-black;
            }
        }
    }

    // Extra block "padding"
    &.has-media + .Block {
        /*margin-top: 4rem;
        @include mq($from: $viewport--md) {
            margin-top: 10rem;
        }
        &:before {
            content: "";
            position: absolute;
            top: -4rem;
            left: 0;
            height: 4rem;
            width: 100%;
            @include mq($from: $viewport--md) {
                top: -10rem;
                height: 10rem;
            }
        }*/
        @each $modifier, $color in $theme-modifiers {
            &.Block--bg-#{$modifier} {
                &:before {
                    //background-color: $color;
                }
            }
        }
    }
}
