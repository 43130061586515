/* Main */
html {
    //background-color: $bg-color;
    color: $text-color;
    font-family: $font-open-sans;
    height: 100%;
    scroll-behavior: smooth;

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    height: 100%;
}

/* Remove focus styles when an element receives focus via the mouse */
:focus:not(:focus-visible) {
    outline: none;
}

/* Show focus styles on keyboard focus */
:focus-visible {
    outline-style: solid;
    outline-color: transparent;
}

.u-visible {
    visibility: visible;
}

.u-invisible {
    visibility: hidden;
}

[x-cloak] {
    display: none !important;
}
