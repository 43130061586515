.Editor {

    &--withoutLastMargin {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &--slim {

    }

    &--large {

    }

    &--columns {
        @include mq($from: $viewport--md) {
            column-count: 2;
            column-gap: 2.5rem;
        }
        @include mq($from: $viewport--xl) {
            column-gap: 5rem;
        }
        // Safari colums fix ...
        > *:not(ul):not(ol) {
            display: flex;
        }
    }

    figure iframe {
        max-width: 100%;
    }
}

// Light background
.Block--bg-white,
.Block--bg-grey-100 {
    .Editor a:not([class]) {
        color: $black;
        text-decoration: underline;
        text-decoration-color: $cc-green;
        text-decoration-thickness: .2rem;
        text-underline-offset: .2rem;
        &:hover,
        &:focus {
            color: $cc-green;
        }
    }
}
