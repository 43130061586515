/* Heading */
h1, .#{$namespace}Alfa,
h2, .#{$namespace}Bravo,
h3, .#{$namespace}Charlie,
h4, .#{$namespace}Delta,
h5, .#{$namespace}Echo,
h6, .#{$namespace}Foxtrot {
    font-family: $font-saira;
    color: var(--block-text-color, #{$heading-color});
    @include mq($until: $viewport--md - 1) {
        hyphens: auto;
    }

    small {
        font-weight: normal;
    }
    strong {
        font-weight: 500;
    }
}

h1, .#{$namespace}Alfa {
    @include font-size($heading-size-1 - 10, $heading-spacing-1 - 10);
    font-weight: 300;
    margin-bottom: 3rem;
    @include mq($viewport--sm) {
        @include font-size($heading-size-1, $heading-spacing-1);
    }
}

h2, .#{$namespace}Bravo {
    @include font-size($heading-size-2 - 8, $heading-spacing-2 - 8);
    font-weight: 300;
    margin-bottom: 2rem;
    @include mq($viewport--sm) {
        @include font-size($heading-size-2, $heading-spacing-2);
    }
}

h3, .#{$namespace}Charlie {
    @include font-size($heading-size-3, $heading-spacing-3);
    font-weight: 300;
    margin-bottom: 2rem;
}

h4, .#{$namespace}Delta {
    @include font-size($heading-size-4, $heading-spacing-4);
    font-weight: 400;
    margin-bottom: 1.5rem;
}

h5, .#{$namespace}Echo {
    @include font-size($heading-size-5, $heading-spacing-5);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .2rem;
    margin-bottom: 1.5rem;
}

h6, .#{$namespace}Foxtrot {
    @include font-size($heading-size-6, $heading-spacing-6);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .2rem;
    margin-bottom: 1.5rem;
}


// A series of classes for setting massive type; for use in heroes, mastheads,
// promos, etc.

.#{$namespace}Giga {
    @include font-size($heading-size-giga, $heading-spacing-giga);
}

.#{$namespace}Mega {
    @include font-size($heading-size-mega, $heading-spacing-mega);
}

.#{$namespace}Kilo {
    @include font-size($heading-size-kilo, $heading-spacing-kilo);
}

.#{$namespace}Milli {
    @include font-size($heading-size-milli, $heading-spacing-milli);
}

.#{$namespace}Micro {
    @include font-size($heading-size-micro, $heading-spacing-micro);
}
