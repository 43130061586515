/* Logo */
.Logo {
    flex-shrink: 0;
    margin-right: auto;

    svg {
        display: block;
        width: 17rem;
        height: 4.4rem;
    }
    path {
        fill: $cc-grey--700;
    }

    &--siteOverlay,
    &--footer {
        opacity: .6;
        path {
            fill: $white;
        }
    }
    /*&--siteOverlay {
        svg {
            width: 10rem;
            height: 2.5rem;
        }
    }*/
    &--footer {
        svg {
            width: 10rem;
            height: 2.5rem;
        }
    }
}
