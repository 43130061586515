/* Button */

.Button {

    @each $modifier, $color in $theme-modifiers {

        &.Button--#{$modifier} {
            background-color: $color;
            @if ($modifier != 'black' and $modifier != 'grey-700') {
                color: $cc-black;
            } @else {
                color: $white;
            }

            &:hover,
            &:focus,
            &.is-hover,
            &.is-focus,
            &.is-active {
                @if ($modifier != 'black' and $modifier != 'grey-700') {
                    background-color: $black;
                    color: $white;
                } @else {
                    background-color: $cc-green;
                    color: $cc-black;
                }
            }
        }

        &.Button--outline-#{$modifier} {
            background-color: transparent;
            border: 1px solid $color;
            color: $color;

            &:hover,
            &:focus,
            &.is-hover,
            &.is-focus,
            &.is-active {
                background-color: $color;
                @if ($modifier != 'black' and $modifier != 'grey-700') {
                    color: $cc-black;
                } @else {
                    color: $white;
                }
            }
        }

        @if ($modifier != 'green') {
            &.Button--alt-#{$modifier} {
                background-color: $color;
                color: $cc-green;

                &:hover,
                &:focus,
                &.is-hover,
                &.is-focus,
                &.is-active {
                    color: $white;
                    @if ($modifier != 'black') {
                        background-color: $cc-black;
                    } @else {
                        background-color: $cc-grey--700;
                    }
                }
            }
        }
        &.Button-small {
            font-size: 1.3rem;
            padding: 1rem 2rem;
            min-height: auto;
        }
    }
}
