/* Card Grid */

.CardGrid {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-gap: 3.75rem;
    margin-bottom: 2.5rem;
    @include mq($from: $viewport--sm) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include mq($from: $viewport--md) {
        grid-gap: 3.75rem 5rem;
    }
    @include mq($from: $viewport--lg) {
        grid-gap: 6.5rem 10rem;
    }
    > * {
        &:nth-child(2n) {
            @include mq($from: $viewport--md) {
                position: relative;
                top: 5rem;
            }
            @include mq($from: $viewport--lg) {
                top: 10rem;
            }
        }
    }

    + .Nav--pagination {
        margin-top: 2.5rem;
        @include mq($from: $viewport--md) {
            margin-top: 5rem;
        }
        @include mq($from: $viewport--xl) {
            margin-top: 6.5rem;
        }
    }
}
