/* Block Form */

.BlockForm {

    /* --- tagline */
    &-tagline {
        display: block;
        margin-bottom: .5rem;
        opacity: .5;
    }

    /* --- title */
    &-title {
        @include mq($viewport--lg) {
            font-size: 5.5rem;
            margin-bottom: 4rem;
        }
    }

    /* --- text */
    &-text {
        margin-bottom: 2rem;
        @include mq($viewport--lg) {
            margin-bottom: 4rem;
        }
    }

    /* --- item (form) */
    &-item {

    }

    &.has-pixels {
        margin-bottom: 5rem;
        @include mq($from: $viewport--lg) {
            margin-bottom: 7.5rem;
        }
    }

    + .Pixels {
        bottom: -5rem;
        @include mq($from: $viewport--lg) {
            bottom: -10rem;
        }
    }
}
